.fade {
	transition: opacity 0.005s linear !important;
}

// .toolbar {
// 	float: left;
// }
.fixed-table-container {
	border: 0 !important;
	border-radius: 0 !important;
}

//Step form modifications
.wizard {
	.content {
		overflow: scroll;
		background: 0 !important;
		border: 1px solid #eee !important;
		border-radius: 0 !important;
		min-height: 24em !important;
		.body {
			width: 100% !important;
			label.error {
				margin: 0 !important;
				font-weight: 500 !important;
			}
		}
	}

	.steps {
		a,
		a:hover,
		a:active {
			border-radius: 0px !important;
			padding: 0.3em 1em !important;
		}
	}
	.form-control-uppercase {
		text-transform: uppercase;
	}
	.form-control-bold {
		font-weight: 600;
	}

	.form-control-sm {
		padding: 0.25rem 0.5rem;
	}
	.upper_five {
		margin-bottom: 0;
		color: #26549a;
		font-weight: 600;
		text-transform: uppercase;
	}
}

#modal-add-client {
	.wizard {
		.steps {
			ul > li {
				width: 25% !important;
			}
		}
	}
}
#modal-add-cert {
	.wizard {
		.steps {
			ul > li {
				width: 20% !important;
			}
		}
	}
}
#modal-add-vehicle {
	.wizard {
		.steps {
			ul > li {
				width: 16.66% !important;
			}
		}
	}
}

#modal-add-certificate {
	.wizard {
		.steps {
			ul > li {
				width: 50% !important;
			}
		}
	}
}

.swal2-input {
	text-align: center;
}

.certificate {
	#modal-view-data {
		.modal-content {
			.loading {
				.overlay {
					position: relative;
					top: -100%;
				}
			}
		}
	}
}

.client {
	#modal-view-data {
		.modal-content {
			.loading {
				.overlay {
					position: relative;
					top: -100%;
				}
			}
		}
	}
}

.realtime {
	#fuel-modal {
		.modal-content {
			.loading {
				.overlay {
					position: relative;
					top: -100%;
				}
			}
		}
	}
}

.common-img-bg {
	background-size: cover;
	background: url(../images/bg_login.jpg) no-repeat center center fixed;
	height: 100%;
}

.small_icon_loader {
	display: block;
	position: absolute;
	bottom: 85px;
	left: 50%;
	font-family: Arial;
	max-width: 178px;
	font-size: 12px;
	line-height: 19px;
	z-index: 1;
	color: #6c7486;
}
.small_text_loader {
	display: block;
	position: absolute;
	bottom: 20px;
	left: 50%;
	font-family: Arial;
	max-width: 320px;
	font-size: 12px;
	line-height: 19px;
	z-index: 1;
	color: #6c7486;
}
@media (min-width: 481px) {
	.small_text_loader {
		transform: translateX(-50%);
		text-align: center;
	}
	.small_icon_loader {
		transform: translateX(-50%);
		text-align: center;
	}
}
@media (max-width: 480px) {
	.small_text_loader {
		transform: translateX(-50%);
		text-align: center;
		font-size: 10px;
		line-height: 17px;
		/* transform: translateZ(400px); */
	}
	.small_icon_loader {
		/* left: 20px;
				right: 112px; */
		bottom: 91px;
		font-size: 10px;
		transform: translateX(-50%);
		text-align: center;
		line-height: 17px;
		/* transform: translateZ(400px); */
	}
}
.login-section-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(255 255 255);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;
}
.darkRedClass {
	background-color: #dd9696;
}
.redClass {
	background-color: #f1cbcb;
}
