// Override Cntainer variables for different screens
.container {
	@media (min-width: 750px) {
		width: 750px !important;
	}
	@media (min-width: 970px) {
		width: 1000px !important;
	}
	@media (min-width: 1200px) {
		width: 1360px !important;
	}
	@media (min-width: 1500px) {
		width: 1622px !important;
	}
}

.navbar-brand {
	@media (min-width: 750px) {
		padding: 15px 15px !important;
	}
	@media (min-width: 970px) {
		padding: 10px 30px !important;
	}
	@media (min-width: 1200px) {
		padding: 10px 30px !important;
	}
	@media (min-width: 1500px) {
		padding: 10px 30px !important;
	}
}
.modal-dialog {
	@media (min-width: 750px) {
		width: auto !important;
		margin: 10px !important;
	}
	@media (min-width: 960px) {
		width: 1000px !important;
		margin: 30px auto !important;
	}
	// @media (min-width: 1200px) {
	// 	padding: 10px 30px !important;
	// }
	// @media (min-width: 1500px) {
	// 	padding: 10px 30px !important;
	// }
}
