/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body {
	height: 100%;
	.layout-boxed & {
		height: 100%;
	}
}

body {
	font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	overflow-x: hidden;
	overflow-y: auto;
}

/* Layout */
.wrapper {
	.clearfix();
	height: 94%;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	.layout-boxed & {
		max-width: 1250px;
		margin: 0 auto;
		min-height: 100%;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
		position: relative;
	}
}

.layout-boxed {
	background-color: @sidebar-light-bg;
}

/*
 * Content Wrapper - contains the main content
 */
.content-wrapper,
.main-footer {
	// Using disposable variable to join statements with a comma
	@transition-rule: @transition-speed @transition-fn,
		margin @transition-speed @transition-fn;
	.transition-transform(@transition-rule);
	margin-left: @sidebar-width;
	z-index: 820;
	// Top nav layout
	.layout-top-nav & {
		margin-left: 0;
	}
	@media (max-width: @screen-xs-max) {
		margin-left: 0;
	}
	// When opening the sidebar on large screens
	.sidebar-collapse & {
		@media (min-width: @screen-sm) {
			margin-left: 0;
		}
	}
	// When opening the sidebar on small screens
	.sidebar-open & {
		@media (max-width: @screen-xs-max) {
			.translate(@sidebar-width, 0);
		}
	}
}

.content-wrapper {
	height: ~'calc(100vh - 60px)';
	background-color: @content-bg;
	z-index: 800;
}

@media (max-width: @screen-header-collapse) {
	.content-wrapper {
		min-height: ~'calc(100vh - 60px)';
	}
}

.main-footer {
	background: #fff;
	padding: 15px;
	color: #444;
}

/* Fixed layout */
.fixed {
	.main-header,
	.main-sidebar,
	.left-side {
		position: fixed;
	}
	.main-header {
		top: 0;
		right: 0;
		left: 0;
	}
	.content-wrapper,
	.right-side {
		padding-top: 50px;
		@media (max-width: @screen-header-collapse) {
			padding-top: 100px;
		}
	}
	&.layout-boxed {
		.wrapper {
			max-width: 100%;
		}
	}
	.wrapper {
		overflow: hidden;
	}
}

.hold-transition {
	.content-wrapper,
	.right-side,
	.main-footer,
	.main-sidebar,
	.left-side,
	.main-header .navbar,
	.main-header .logo,
	.menu-open .fa-angle-left {
		/* Fix for IE */
		.transition(none);
	}
}

/* Content */
.content {
	min-height: 250px;
	padding: 15px;
	.container-fixed(@grid-gutter-width);
}

/* H1 - H6 font */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: 'Source Sans Pro', sans-serif;
}

/* General Links */
a {
	color: @link-color;
}

a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
	color: @link-hover-color;
}

/* Page Header */
.page-header {
	margin: 10px 0 20px 0;
	font-size: 22px;

	> small {
		color: #666;
		display: block;
		margin-top: 5px;
	}
}

/* Override JS toast CLose button  */
.close-jq-toast-single {
	height: 2.35294rem !important;
	width: 2.35294rem !important;
	text-align: center;
	padding: 0 !important;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	top: -1.17647rem !important;
	right: -1.17647rem !important;
	background: rgb(255 255 255);
	border-radius: 50%;
	-webkit-box-shadow: 0 2px 4px 0 #0000001a;
	box-shadow: 0 2px 4px 0 #0000001a;
	align-items: center;
	display: flex;
	justify-content: center;
	align-content: center;
	cursor: pointer;
	color: rgb(169 146 130);
	font-size: 19px;
	font-weight: 900;
}

.jq-toast-single {
	border-radius: 0 !important;
	font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
	h2 {
		font-family: inherit;
	}
}
