/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body {
  height: 100%;
}
.layout-boxed html,
.layout-boxed body {
  height: 100%;
}
body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}
/* Layout */
.wrapper {
  height: 94%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}
.wrapper:after {
  clear: both;
}
.layout-boxed .wrapper {
  max-width: 1250px;
  margin: 0 auto;
  min-height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
.layout-boxed {
  background-color: #f9fafc;
}
/*
 * Content Wrapper - contains the main content
 */
.content-wrapper,
.main-footer {
  -webkit-transition: -webkit-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 230px;
  z-index: 820;
}
.layout-top-nav .content-wrapper,
.layout-top-nav .main-footer {
  margin-left: 0;
}
@media (max-width: 767px) {
  .content-wrapper,
  .main-footer {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .sidebar-open .content-wrapper,
  .sidebar-open .main-footer {
    -webkit-transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
    -o-transform: translate(230px, 0);
    transform: translate(230px, 0);
  }
}
.content-wrapper {
  height: calc(100vh - 60px);
  background-color: #f1f1f1;
  z-index: 800;
}
@media (max-width: 767px) {
  .content-wrapper {
    min-height: calc(100vh - 60px);
  }
}
.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
}
/* Fixed layout */
.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed;
}
.fixed .main-header {
  top: 0;
  right: 0;
  left: 0;
}
.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 100px;
  }
}
.fixed.layout-boxed .wrapper {
  max-width: 100%;
}
.fixed .wrapper {
  overflow: hidden;
}
.hold-transition .content-wrapper,
.hold-transition .right-side,
.hold-transition .main-footer,
.hold-transition .main-sidebar,
.hold-transition .left-side,
.hold-transition .main-header .navbar,
.hold-transition .main-header .logo,
.hold-transition .menu-open .fa-angle-left {
  /* Fix for IE */
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
/* Content */
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
/* H1 - H6 font */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Source Sans Pro', sans-serif;
}
/* General Links */
a {
  color: #1d7f9b;
}
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #2db2d8;
}
/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;
}
.page-header > small {
  color: #666;
  display: block;
  margin-top: 5px;
}
/* Override JS toast CLose button  */
.close-jq-toast-single {
  height: 2.35294rem !important;
  width: 2.35294rem !important;
  text-align: center;
  padding: 0 !important;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  top: -1.17647rem !important;
  right: -1.17647rem !important;
  background: rgb(255 255 255);
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px 0 #0000001a;
  box-shadow: 0 2px 4px 0 #0000001a;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  color: rgb(169 146 130);
  font-size: 19px;
  font-weight: 900;
}
.jq-toast-single {
  border-radius: 0 !important;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.jq-toast-single h2 {
  font-family: inherit;
}
/*
 * Component: Main Header
 * ----------------------
 */
.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
}
.main-header .navbar {
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  min-height: 50px;
  border-radius: 0;
}
.layout-top-nav .main-header .navbar {
  margin-left: 0;
}
.main-header #navbar-search-input.form-control {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.main-header #navbar-search-input.form-control:focus,
.main-header #navbar-search-input.form-control:active {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
  color: #ccc;
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.main-header .navbar-custom-menu,
.main-header .navbar-right {
  float: right;
}
@media (max-width: 991px) {
  .main-header .navbar-custom-menu a,
  .main-header .navbar-right a {
    color: inherit;
    background: transparent;
  }
}
@media (max-width: 767px) {
  .main-header .navbar-right {
    float: none;
  }
  .navbar-collapse .main-header .navbar-right {
    margin: 7.5px -15px;
  }
  .main-header .navbar-right > li {
    color: inherit;
    border: 0;
  }
}
.main-header .sidebar-toggle {
  float: left;
  background-color: transparent;
  background-image: none;
  padding: 15px 15px;
  font-family: fontAwesome;
}
.main-header .sidebar-toggle:before {
  content: '\f0c9';
}
.main-header .sidebar-toggle:hover {
  color: #fff;
}
.main-header .sidebar-toggle:focus,
.main-header .sidebar-toggle:active {
  background: transparent;
}
.main-header .sidebar-toggle.fa5 {
  font-family: 'Font Awesome\ 5 Free';
}
.main-header .sidebar-toggle.fa5:before {
  content: '\f0c9';
  font-weight: 900;
}
.main-header .sidebar-toggle .icon-bar {
  display: none;
}
.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
  margin-right: 5px;
}
.main-header .navbar .nav > li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
}
.main-header .logo {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}
.main-header .logo img {
  padding: 4px;
  object-fit: contain;
  margin: 0 auto;
}
.main-header .logo .logo-lg {
  display: block;
}
.main-header .logo .logo-lg img {
  max-width: 200px;
  max-height: 50px;
}
.main-header .logo .logo-lg .brandlogo-image {
  margin-top: 8px;
  margin-right: 10px;
  margin-left: -5px;
}
.main-header .logo .logo-mini {
  display: none;
}
.main-header .logo .logo-mini img {
  max-width: 50px;
  max-height: 50px;
}
.main-header .logo .logo-mini .brandlogo-image {
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
}
.main-header .logo .brandlogo-image {
  float: left;
  height: 34px;
  width: auto;
}
.main-header .navbar-brand {
  color: #fff;
}
.content-header {
  position: relative;
  padding: 15px 15px 0 15px;
}
.content-header > h1 {
  margin: 0;
  font-size: 24px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  color: #4a6076;
  font-size: 23px;
}
.content-header > h1 > small {
  font-size: 15px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 300;
}
.content-header > .breadcrumb {
  float: right;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  padding: 7px 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  border-radius: 2px;
}
.content-header > .breadcrumb > li > a {
  color: #444;
  text-decoration: none;
  display: inline-block;
}
.content-header > .breadcrumb > li > a > .fa,
.content-header > .breadcrumb > li > a > .glyphicon,
.content-header > .breadcrumb > li > a > .ion {
  margin-right: 5px;
}
.content-header > .breadcrumb > li + li:before {
  content: '>\00a0';
}
@media (max-width: 991px) {
  .content-header > .breadcrumb {
    position: relative;
    margin-top: 5px;
    top: 0;
    right: 0;
    float: none;
    background: #d2d6de;
    padding-left: 10px;
  }
  .content-header > .breadcrumb li:before {
    color: #97a0b3;
  }
}
.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0;
  padding: 15px 15px;
}
@media (max-width: 991px) {
  .navbar-custom-menu .navbar-nav > li {
    float: left;
  }
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left;
  }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .main-header {
    position: relative;
  }
  .main-header .logo,
  .main-header .navbar {
    width: 100%;
    float: none;
  }
  .main-header .navbar {
    margin: 0;
  }
  .main-header .navbar-custom-menu {
    float: right;
  }
}
@media (max-width: 991px) {
  .navbar-collapse.pull-left {
    float: none !important;
  }
  .navbar-collapse.pull-left + .navbar-custom-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 40px;
  }
}
/*
 * Component: Sidebar
 * ------------------
 */
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.sidebar {
  padding-bottom: 10px;
}
.sidebar-form input:focus {
  border-color: transparent;
}
.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}
.user-panel:before,
.user-panel:after {
  content: " ";
  display: table;
}
.user-panel:after {
  clear: both;
}
.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}
.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
}
.user-panel > .info > p {
  font-weight: 600;
  margin-bottom: 9px;
}
.user-panel > .info > a {
  text-decoration: none;
  padding-right: 5px;
  margin-top: 3px;
  font-size: 11px;
}
.user-panel > .info > a > .fa,
.user-panel > .info > a > .ion,
.user-panel > .info > a > .glyphicon {
  margin-right: 3px;
}
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li > a {
  padding: 12px 5px 12px 15px;
  display: block;
}
.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
  width: 20px;
}
.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-right: 5px;
}
.sidebar-menu > li .badge {
  margin-top: 3px;
}
.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}
.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}
.sidebar-menu li > a > .fa-angle-left {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}
.sidebar-menu .menu-open > a > .fa-angle-left,
.sidebar-menu .menu-open > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidebar-menu .active > .treeview-menu {
  display: block;
}
/*
 * Component: Sidebar Mini
 */
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px !important;
    z-index: 840;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 50px !important;
    z-index: 850;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
    margin-right: 0;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
    border-top-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .sidebar-form,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0);
  }
  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px;
  }
}
@media (min-width: 768px) {
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    display: block !important;
    position: absolute;
    width: 180px;
    left: 50px;
  }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span {
    top: 0;
    margin-left: -3px;
    padding: 12px 5px 12px 20px;
    background-color: inherit;
  }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container {
    position: relative !important;
    float: right;
    width: auto !important;
    left: 180px !important;
    top: -22px !important;
    z-index: 900;
  }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container > .label:not(:first-of-type) {
    display: none;
  }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    top: 44px;
    margin-left: 0;
  }
}
.sidebar-expanded-on-hover .main-footer,
.sidebar-expanded-on-hover .content-wrapper {
  margin-left: 50px;
}
.sidebar-expanded-on-hover .main-sidebar {
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.125);
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-menu:hover {
  overflow: visible;
}
.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}
.sidebar-menu li > a {
  position: relative;
}
.sidebar-menu li > a > .pull-right-container {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}
/*
 * Component: Control sidebar. By default, this is the right sidebar.
 */
.control-sidebar-bg {
  position: fixed;
  z-index: 1000;
  bottom: 0;
}
.control-sidebar-bg,
.control-sidebar {
  top: 0;
  right: -230px;
  width: 230px;
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}
.control-sidebar {
  position: absolute;
  padding-top: 50px;
  z-index: 1010;
}
@media (max-width: 767px) {
  .control-sidebar {
    padding-top: 100px;
  }
}
.control-sidebar > .tab-content {
  padding: 10px 15px;
}
.control-sidebar.control-sidebar-open,
.control-sidebar.control-sidebar-open + .control-sidebar-bg {
  right: 0;
}
.control-sidebar-open .control-sidebar-bg,
.control-sidebar-open .control-sidebar {
  right: 0;
}
@media (min-width: 768px) {
  .control-sidebar-open .content-wrapper,
  .control-sidebar-open .right-side,
  .control-sidebar-open .main-footer {
    margin-right: 230px;
  }
}
.fixed .control-sidebar {
  position: fixed;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
}
.nav-tabs.control-sidebar-tabs > li:first-of-type > a,
.nav-tabs.control-sidebar-tabs > li:first-of-type > a:hover,
.nav-tabs.control-sidebar-tabs > li:first-of-type > a:focus {
  border-left-width: 0;
}
.nav-tabs.control-sidebar-tabs > li > a {
  border-radius: 0;
}
.nav-tabs.control-sidebar-tabs > li > a,
.nav-tabs.control-sidebar-tabs > li > a:hover {
  border-top: none;
  border-right: none;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.nav-tabs.control-sidebar-tabs > li > a .icon {
  font-size: 16px;
}
.nav-tabs.control-sidebar-tabs > li.active > a,
.nav-tabs.control-sidebar-tabs > li.active > a:hover,
.nav-tabs.control-sidebar-tabs > li.active > a:focus,
.nav-tabs.control-sidebar-tabs > li.active > a:active {
  border-top: none;
  border-right: none;
  border-bottom: none;
}
@media (max-width: 768px) {
  .nav-tabs.control-sidebar-tabs {
    display: table;
  }
  .nav-tabs.control-sidebar-tabs > li {
    display: table-cell;
  }
}
.control-sidebar-heading {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
}
.control-sidebar-subheading {
  display: block;
  font-weight: 400;
  font-size: 14px;
}
.control-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
}
.control-sidebar-menu > li > a {
  display: block;
  padding: 10px 15px;
}
.control-sidebar-menu > li > a:before,
.control-sidebar-menu > li > a:after {
  content: " ";
  display: table;
}
.control-sidebar-menu > li > a:after {
  clear: both;
}
.control-sidebar-menu > li > a > .control-sidebar-subheading {
  margin-top: 0;
}
.control-sidebar-menu .menu-icon {
  float: left;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
}
.control-sidebar-menu .menu-info {
  margin-left: 45px;
  margin-top: 3px;
}
.control-sidebar-menu .menu-info > .control-sidebar-subheading {
  margin: 0;
}
.control-sidebar-menu .menu-info > p {
  margin: 0;
  font-size: 11px;
}
.control-sidebar-menu .progress {
  margin: 0;
}
.control-sidebar-dark {
  color: #b8c7ce;
}
.control-sidebar-dark,
.control-sidebar-dark + .control-sidebar-bg {
  background: #222d32;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs {
  border-bottom: #1c2529;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a {
  background: #181f23;
  color: #b8c7ce;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:focus {
  border-left-color: #141a1d;
  border-bottom-color: #141a1d;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:focus,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:active {
  background: #1c2529;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover {
  color: #fff;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:focus,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:active {
  background: #222d32;
  color: #fff;
}
.control-sidebar-dark .control-sidebar-heading,
.control-sidebar-dark .control-sidebar-subheading {
  color: #fff;
}
.control-sidebar-dark .control-sidebar-menu > li > a:hover {
  background: #1e282c;
}
.control-sidebar-dark .control-sidebar-menu > li > a .menu-info > p {
  color: #b8c7ce;
}
.control-sidebar-light {
  color: #5e5e5e;
}
.control-sidebar-light,
.control-sidebar-light + .control-sidebar-bg {
  background: #f9fafc;
  border-left: 1px solid #d2d6de;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs {
  border-bottom: #d2d6de;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a {
  background: #e8ecf4;
  color: #444;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:focus {
  border-left-color: #d2d6de;
  border-bottom-color: #d2d6de;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:focus,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:active {
  background: #eff1f7;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:focus,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:active {
  background: #f9fafc;
  color: #111;
}
.control-sidebar-light .control-sidebar-heading,
.control-sidebar-light .control-sidebar-subheading {
  color: #111;
}
.control-sidebar-light .control-sidebar-menu {
  margin-left: -14px;
}
.control-sidebar-light .control-sidebar-menu > li > a:hover {
  background: #f4f4f5;
}
.control-sidebar-light .control-sidebar-menu > li > a .menu-info > p {
  color: #5e5e5e;
}
/*
 * Component: Dropdown menus
 * -------------------------
 */
/*Dropdowns in general*/
.dropdown-menu {
  box-shadow: none;
  border-color: #eee;
}
.dropdown-menu > li > a {
  color: #777;
}
.dropdown-menu > li > a > .glyphicon,
.dropdown-menu > li > a > .fa,
.dropdown-menu > li > a > .ion {
  margin-right: 10px;
}
.dropdown-menu > li > a:hover {
  background-color: #e1e3e9;
  color: #333;
}
.dropdown-menu > .divider {
  background-color: #eee;
}
.navbar-nav > .notifications-menu > .dropdown-menu,
.navbar-nav > .messages-menu > .dropdown-menu,
.navbar-nav > .tasks-menu > .dropdown-menu {
  width: 280px;
  padding: 0 0 0 0;
  margin: 0;
  top: 100%;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li,
.navbar-nav > .messages-menu > .dropdown-menu > li,
.navbar-nav > .tasks-menu > .dropdown-menu > li {
  position: relative;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.header,
.navbar-nav > .messages-menu > .dropdown-menu > li.header,
.navbar-nav > .tasks-menu > .dropdown-menu > li.header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ffffff;
  padding: 7px 10px;
  border-bottom: 1px solid #f4f4f4;
  color: #444444;
  font-size: 14px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  background-color: #fff;
  padding: 7px 10px;
  border-bottom: 1px solid #eeeeee;
  color: #444 !important;
  text-align: center;
}
@media (max-width: 991px) {
  .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
    background: #fff !important;
    color: #444 !important;
  }
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a:hover {
  text-decoration: none;
  font-weight: normal;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
  display: block;
  white-space: nowrap;
  /* Prevent text from breaking */
  border-bottom: 1px solid #f4f4f4;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a:hover {
  background: #f4f4f4;
  text-decoration: none;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .glyphicon,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .fa,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .ion {
  width: 20px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a {
  margin: 0;
  padding: 10px 10px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > div > img {
  margin: auto 10px auto auto;
  width: 40px;
  height: 40px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 {
  padding: 0;
  margin: 0 0 0 45px;
  color: #444444;
  font-size: 15px;
  position: relative;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 > small {
  color: #999999;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
  margin: 0 0 0 45px;
  font-size: 12px;
  color: #888888;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:before,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  content: " ";
  display: table;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  clear: both;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
  padding: 10px;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > h3 {
  font-size: 14px;
  padding: 0;
  margin: 0 0 10px 0;
  color: #666666;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > .progress {
  padding: 0;
  margin: 0;
}
.navbar-nav > .user-menu > .dropdown-menu {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 1px 0 0 0;
  border-top-width: 0;
  width: 280px;
}
.navbar-nav > .user-menu > .dropdown-menu,
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: 175px;
  padding: 10px;
  text-align: center;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
  z-index: 5;
  height: 90px;
  width: 90px;
  border: 3px solid;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  z-index: 5;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  margin-top: 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
  display: block;
  font-size: 12px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  padding: 15px;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #dddddd;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body:before,
.navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
  content: " ";
  display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
  clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body a {
  color: #444 !important;
}
@media (max-width: 991px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
    background: #fff !important;
    color: #444 !important;
  }
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer {
  background-color: #f9f9f9;
  padding: 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:before,
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
  content: " ";
  display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
  clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default {
  color: #666666;
}
@media (max-width: 991px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f9f9f9;
  }
}
.navbar-nav > .user-menu .user-image {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px;
}
@media (max-width: 767px) {
  .navbar-nav > .user-menu .user-image {
    float: none;
    margin-right: 0;
    margin-top: -8px;
    line-height: 10px;
  }
}
/* Add fade animation to dropdown menus by appending
 the class .animated-dropdown-menu to the .dropdown-menu ul (or ol)*/
.open:not(.dropup) > .animated-dropdown-menu {
  backface-visibility: visible !important;
  -webkit-animation: flipInX 0.7s both;
  -o-animation: flipInX 0.7s both;
  animation: flipInX 0.7s both;
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}
/* Fix dropdown menu in navbars */
.navbar-custom-menu > .navbar-nav > li {
  position: relative;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}
@media (max-width: 991px) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
  }
  .navbar-custom-menu > .navbar-nav > li {
    position: static;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 5%;
    left: auto;
    border: 1px solid #ddd;
    background: #fff;
  }
}
/*
 * Component: Form
 * ---------------
 */
.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #d2d6de;
}
.form-control:focus {
  border-color: #1d7f9b;
  box-shadow: none;
}
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #bbb;
  opacity: 1;
}
.form-control:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-group.has-success label {
  color: #00a65a;
}
.form-group.has-success .form-control,
.form-group.has-success .input-group-addon {
  border-color: #00a65a;
  box-shadow: none;
}
.form-group.has-success .help-block {
  color: #00a65a;
}
.form-group.has-warning label {
  color: #f39c12;
}
.form-group.has-warning .form-control,
.form-group.has-warning .input-group-addon {
  border-color: #f39c12;
  box-shadow: none;
}
.form-group.has-warning .help-block {
  color: #f39c12;
}
.form-group.has-error label {
  color: #dd142f;
}
.form-group.has-error .form-control,
.form-group.has-error .input-group-addon {
  border-color: #dd142f;
  box-shadow: none;
}
.form-group.has-error .help-block {
  color: #dd142f;
}
/* Input group */
.input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff;
}
/* button groups */
.btn-group-vertical .btn.btn-flat:first-of-type,
.btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0;
}
.icheck > label {
  padding-left: 0;
}
/* support Font Awesome icons in form-control */
.form-control-feedback.fa {
  line-height: 34px;
}
.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: 46px;
}
.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: 30px;
}
/*
 * Component: Progress Bar
 * -----------------------
 */
.progress,
.progress > .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress,
.progress > .progress-bar,
.progress .progress-bar,
.progress > .progress-bar .progress-bar {
  border-radius: 1px;
}
/* size variation */
.progress.sm,
.progress-sm {
  height: 10px;
}
.progress.sm,
.progress-sm,
.progress.sm .progress-bar,
.progress-sm .progress-bar {
  border-radius: 1px;
}
.progress.xs,
.progress-xs {
  height: 7px;
}
.progress.xs,
.progress-xs,
.progress.xs .progress-bar,
.progress-xs .progress-bar {
  border-radius: 1px;
}
.progress.xxs,
.progress-xxs {
  height: 3px;
}
.progress.xxs,
.progress-xxs,
.progress.xxs .progress-bar,
.progress-xxs .progress-bar {
  border-radius: 1px;
}
/* Vertical bars */
.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
}
.progress.vertical > .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.progress.vertical.sm,
.progress.vertical.progress-sm {
  width: 20px;
}
.progress.vertical.xs,
.progress.vertical.progress-xs {
  width: 10px;
}
.progress.vertical.xxs,
.progress.vertical.progress-xxs {
  width: 3px;
}
.progress-group .progress-text {
  font-weight: 600;
}
.progress-group .progress-number {
  float: right;
}
/* Remove margins from progress bars when put in a table */
.table tr > td .progress {
  margin: 0;
}
.progress-bar-light-blue,
.progress-bar-primary {
  background-color: #1d7f9b;
}
.progress-striped .progress-bar-light-blue,
.progress-striped .progress-bar-primary {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-green,
.progress-bar-success {
  background-color: #00a65a;
}
.progress-striped .progress-bar-green,
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-aqua,
.progress-bar-info {
  background-color: #3c8dbc;
}
.progress-striped .progress-bar-aqua,
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-yellow,
.progress-bar-warning {
  background-color: #f39c12;
}
.progress-striped .progress-bar-yellow,
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-red,
.progress-bar-danger {
  background-color: #dd142f;
}
.progress-striped .progress-bar-red,
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
/*
 * Component: Small Box
 * --------------------
 */
.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.small-box > .inner {
  padding: 10px;
}
.small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.small-box > .small-box-footer:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.15);
}
.small-box h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
}
.small-box p {
  font-size: 15px;
}
.small-box p > small {
  display: block;
  color: #f9f9f9;
  font-size: 13px;
  margin-top: 5px;
}
.small-box h3,
.small-box p {
  z-index: 5;
}
.small-box .icon {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15);
}
.small-box:hover {
  text-decoration: none;
  color: #f9f9f9;
}
.small-box:hover .icon {
  font-size: 95px;
}
@media (max-width: 767px) {
  .small-box {
    text-align: center;
  }
  .small-box .icon {
    display: none;
  }
  .small-box p {
    font-size: 12px;
  }
}
/*
 * Component: Box
 * --------------
 */
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box.box-primary {
  border-top-color: #1d7f9b;
}
.box.box-info {
  border-top-color: #3c8dbc;
}
.box.box-danger {
  border-top-color: #dd142f;
}
.box.box-warning {
  border-top-color: #f39c12;
}
.box.box-success {
  border-top-color: #00a65a;
}
.box.box-default {
  border-top-color: #d2d6de;
}
.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}
.box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}
.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}
.box.height-control .box-body {
  max-height: 300px;
  overflow: auto;
}
.box .border-right {
  border-right: 1px solid #f4f4f4;
}
.box .border-left {
  border-left: 1px solid #f4f4f4;
}
.box.box-solid {
  border-top: 0;
}
.box.box-solid > .box-header .btn.btn-default {
  background: transparent;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
  border: 1px solid #d2d6de;
}
.box.box-solid.box-default > .box-header {
  color: #444;
  background: #d2d6de;
  background-color: #d2d6de;
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
  color: #444;
}
.box.box-solid.box-primary {
  border: 1px solid #1d7f9b;
}
.box.box-solid.box-primary > .box-header {
  color: #fff;
  background: #1d7f9b;
  background-color: #1d7f9b;
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-info {
  border: 1px solid #3c8dbc;
}
.box.box-solid.box-info > .box-header {
  color: #fff;
  background: #3c8dbc;
  background-color: #3c8dbc;
}
.box.box-solid.box-info > .box-header a,
.box.box-solid.box-info > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-danger {
  border: 1px solid #dd142f;
}
.box.box-solid.box-danger > .box-header {
  color: #fff;
  background: #dd142f;
  background-color: #dd142f;
}
.box.box-solid.box-danger > .box-header a,
.box.box-solid.box-danger > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-warning {
  border: 1px solid #f39c12;
}
.box.box-solid.box-warning > .box-header {
  color: #fff;
  background: #f39c12;
  background-color: #f39c12;
}
.box.box-solid.box-warning > .box-header a,
.box.box-solid.box-warning > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-success {
  border: 1px solid #00a65a;
}
.box.box-solid.box-success > .box-header {
  color: #fff;
  background: #00a65a;
  background-color: #00a65a;
}
.box.box-solid.box-success > .box-header a,
.box.box-solid.box-success > .box-header .btn {
  color: #fff;
}
.box.box-solid > .box-header > .box-tools .btn {
  border: 0;
  box-shadow: none;
}
.box.box-solid[class*='bg'] > .box-header {
  color: #fff;
}
.box .box-group > .box {
  margin-bottom: 5px;
}
.box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}
.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.collapsed-box .box-header.with-border {
  border-bottom: none;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
  margin-right: 5px;
}
.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}
.box-header > .box-tools [data-toggle="tooltip"] {
  position: relative;
}
.box-header > .box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}
.box-header > .box-tools .dropdown-menu > li > a {
  color: #444 !important;
}
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #97a0b3;
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: #606c84;
}
.btn-box-tool.btn:active {
  box-shadow: none;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}
.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.box-body > .table {
  margin-bottom: 0;
}
.box-body .fc {
  margin-top: 5px;
}
.box-body .full-width-chart {
  margin: -19px;
}
.box-body.no-padding .full-width-chart {
  margin: -9px;
}
.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}
.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}
.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #fff;
}
.chart-legend {
  margin: 10px 0;
}
@media (max-width: 991px) {
  .chart-legend > li {
    float: left;
    margin-right: 10px;
  }
}
.box-comments {
  background: #f7f7f7;
}
.box-comments .box-comment {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}
.box-comments .box-comment:before,
.box-comments .box-comment:after {
  content: " ";
  display: table;
}
.box-comments .box-comment:after {
  clear: both;
}
.box-comments .box-comment:last-of-type {
  border-bottom: 0;
}
.box-comments .box-comment:first-of-type {
  padding-top: 0;
}
.box-comments .box-comment img {
  float: left;
}
.box-comments .comment-text {
  margin-left: 40px;
  color: #555;
}
.box-comments .username {
  color: #444;
  display: block;
  font-weight: 600;
}
.box-comments .text-muted {
  font-weight: 400;
  font-size: 12px;
}
/* Widget: TODO LIST */
.todo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}
.todo-list > li {
  border-radius: 2px;
  padding: 10px;
  background: #f4f4f4;
  margin-bottom: 2px;
  border-left: 2px solid #e6e7e8;
  color: #444;
}
.todo-list > li:last-of-type {
  margin-bottom: 0;
}
.todo-list > li > input[type='checkbox'] {
  margin: 0 10px 0 5px;
}
.todo-list > li .text {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}
.todo-list > li .label {
  margin-left: 10px;
  font-size: 9px;
}
.todo-list > li .tools {
  display: none;
  float: right;
  color: #dd142f;
}
.todo-list > li .tools > .fa,
.todo-list > li .tools > .glyphicon,
.todo-list > li .tools > .ion {
  margin-right: 5px;
  cursor: pointer;
}
.todo-list > li:hover .tools {
  display: inline-block;
}
.todo-list > li.done {
  color: #999;
}
.todo-list > li.done .text {
  text-decoration: line-through;
  font-weight: 500;
}
.todo-list > li.done .label {
  background: #d2d6de !important;
}
.todo-list .danger {
  border-left-color: #dd142f;
}
.todo-list .warning {
  border-left-color: #f39c12;
}
.todo-list .info {
  border-left-color: #3c8dbc;
}
.todo-list .success {
  border-left-color: #00a65a;
}
.todo-list .primary {
  border-left-color: #1d7f9b;
}
.todo-list .handle {
  display: inline-block;
  cursor: move;
  margin: 0 5px;
}
/* Chat widget (DEPRECATED - this will be removed in the next major release. Use Direct Chat instead)*/
.chat {
  padding: 5px 20px 5px 10px;
}
.chat .item {
  margin-bottom: 10px;
}
.chat .item:before,
.chat .item:after {
  content: " ";
  display: table;
}
.chat .item:after {
  clear: both;
}
.chat .item > img {
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.chat .item > .online {
  border: 2px solid #00a65a;
}
.chat .item > .offline {
  border: 2px solid #dd142f;
}
.chat .item > .message {
  margin-left: 55px;
  margin-top: -40px;
}
.chat .item > .message > .name {
  display: block;
  font-weight: 600;
}
.chat .item > .attachment {
  border-radius: 3px;
  background: #f4f4f4;
  margin-left: 65px;
  margin-right: 15px;
  padding: 10px;
}
.chat .item > .attachment > h4 {
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 14px;
}
.chat .item > .attachment > p,
.chat .item > .attachment > .filename {
  font-weight: 600;
  font-size: 13px;
  font-style: italic;
  margin: 0;
}
.chat .item > .attachment:before,
.chat .item > .attachment:after {
  content: " ";
  display: table;
}
.chat .item > .attachment:after {
  clear: both;
}
.box-input {
  max-width: 200px;
}
.modal .panel-body {
  color: #444;
}
/*
 * Component: Info Box
 * -------------------
 */
.info-box {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 15px;
}
.info-box small {
  font-size: 14px;
}
.info-box .progress {
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px -10px;
  height: 2px;
}
.info-box .progress,
.info-box .progress .progress-bar {
  border-radius: 0;
}
.info-box .progress .progress-bar {
  background: #fff;
}
.info-box-icon {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  height: 90px;
  width: 90px;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0, 0, 0, 0.2);
}
.info-box-icon > img {
  max-width: 100%;
}
.info-box-content {
  padding: 5px 10px;
  margin-left: 90px;
}
.info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}
.progress-description,
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-box-text {
  text-transform: uppercase;
}
.info-box-more {
  display: block;
}
.progress-description {
  margin: 0;
}
/*
 * Component: Timeline
 * -------------------
 */
.timeline {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}
.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ddd;
  left: 31px;
  margin: 0;
  border-radius: 2px;
}
.timeline > li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-item {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
}
.timeline > li > .timeline-item > .time {
  color: #999;
  float: right;
  padding: 10px;
  font-size: 12px;
}
.timeline > li > .timeline-item > .timeline-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  font-size: 16px;
  line-height: 1.1;
}
.timeline > li > .timeline-item > .timeline-header > a {
  font-weight: 600;
}
.timeline > li > .timeline-item > .timeline-body,
.timeline > li > .timeline-item > .timeline-footer {
  padding: 10px;
}
.timeline > li > .fa,
.timeline > li > .glyphicon,
.timeline > li > .ion {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  color: #666;
  background: #d2d6de;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}
.timeline > .time-label > span {
  font-weight: 600;
  padding: 5px;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
}
.timeline-inverse > li > .timeline-item {
  background: #f0f0f0;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline-inverse > li > .timeline-item > .timeline-header {
  border-bottom-color: #ddd;
}
/*
 * Component: Button
 * -----------------
 */
.btn {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
}
.btn.uppercase {
  text-transform: uppercase;
}
.btn.btn-flat {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-width: 1px;
}
.btn:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:focus {
  outline: none;
}
.btn.btn-file {
  position: relative;
  overflow: hidden;
}
.btn.btn-file > input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  opacity: 0;
  filter: alpha(opacity=0);
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
}
.btn-default:hover,
.btn-default:active,
.btn-default.hover {
  background-color: #e7e7e7;
}
.btn-primary {
  background-color: #1d7f9b;
  border-color: #196d86;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.hover {
  background-color: #196d86;
}
.btn-success {
  background-color: #00a65a;
  border-color: #008d4c;
}
.btn-success:hover,
.btn-success:active,
.btn-success.hover {
  background-color: #008d4c;
}
.btn-info {
  background-color: #3c8dbc;
  border-color: #367fa9;
}
.btn-info:hover,
.btn-info:active,
.btn-info.hover {
  background-color: #367fa9;
}
.btn-danger {
  background-color: #dd142f;
  border-color: #c6122a;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.hover {
  background-color: #c6122a;
}
.btn-warning {
  background-color: #f39c12;
  border-color: #e08e0b;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.hover {
  background-color: #e08e0b;
}
.btn-outline {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn[class*='bg-']:hover {
  -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
}
.btn-app {
  border-radius: 3px;
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
}
.btn-app > .fa,
.btn-app > .glyphicon,
.btn-app > .ion {
  font-size: 20px;
  display: block;
}
.btn-app:hover {
  background: #f4f4f4;
  color: #444;
  border-color: #aaa;
}
.btn-app:active,
.btn-app:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-app > .badge {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 10px;
  font-weight: 400;
}
/*
 * Component: Callout
 * ------------------
 */
.callout {
  border-radius: 3px;
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}
.callout a {
  color: #fff;
  text-decoration: underline;
}
.callout a:hover {
  color: #eee;
}
.callout h4 {
  margin-top: 0;
  font-weight: 600;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout code,
.callout .highlight {
  background-color: #fff;
}
.callout.callout-danger {
  border-color: #ae1025;
}
.callout.callout-warning {
  border-color: #c87f0a;
}
.callout.callout-info {
  border-color: #307095;
}
.callout.callout-success {
  border-color: #00733e;
}
/*
 * Component: alert
 * ----------------
 */
.alert {
  border-radius: 3px;
}
.alert h4 {
  font-weight: 600;
}
.alert .icon {
  margin-right: 10px;
}
.alert .close {
  color: #000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.alert .close:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.alert a {
  color: #fff;
  text-decoration: underline;
}
.alert-success {
  border-color: #008d4c;
}
.alert-danger,
.alert-error {
  border-color: #c6122a;
}
.alert-warning {
  border-color: #e08e0b;
}
.alert-info {
  border-color: #367fa9;
}
/*
 * Component: Nav
 * --------------
 */
.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
  color: #444;
  background: #f7f7f7;
}
/* NAV PILLS */
.nav-pills > li > a {
  border-radius: 0;
  border-top: 3px solid transparent;
  color: #444;
}
.nav-pills > li > a > .fa,
.nav-pills > li > a > .glyphicon,
.nav-pills > li > a > .ion {
  margin-right: 5px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  border-top-color: #1d7f9b;
}
.nav-pills > li.active > a {
  font-weight: 600;
}
/* NAV STACKED */
.nav-stacked > li > a {
  border-radius: 0;
  border-top: 0;
  border-left: 3px solid transparent;
  color: #444;
}
.nav-stacked > li.active > a,
.nav-stacked > li.active > a:hover {
  background: transparent;
  color: #444;
  border-top: 0;
  border-left-color: #1d7f9b;
}
.nav-stacked > li.header {
  border-bottom: 1px solid #ddd;
  color: #777;
  margin-bottom: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
}
/* NAV TABS */
.nav-tabs-custom {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.nav-tabs-custom > .nav-tabs {
  margin: 0;
  border-bottom-color: #f4f4f4;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.nav-tabs-custom > .nav-tabs > li {
  border-top: 3px solid transparent;
  margin-bottom: -2px;
  margin-right: 5px;
}
.nav-tabs-custom > .nav-tabs > li.disabled > a {
  color: #777;
}
.nav-tabs-custom > .nav-tabs > li > a {
  color: #444;
  border-radius: 0;
}
.nav-tabs-custom > .nav-tabs > li > a.text-muted {
  color: #999;
}
.nav-tabs-custom > .nav-tabs > li > a,
.nav-tabs-custom > .nav-tabs > li > a:hover {
  background: transparent;
  margin: 0;
}
.nav-tabs-custom > .nav-tabs > li > a:hover {
  color: #999;
}
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:hover,
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:focus,
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:active {
  border-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: #1d7f9b;
}
.nav-tabs-custom > .nav-tabs > li.active > a,
.nav-tabs-custom > .nav-tabs > li.active:hover > a {
  background-color: #fff;
  color: #444;
}
.nav-tabs-custom > .nav-tabs > li.active > a {
  border-top-color: transparent;
  border-left-color: #f4f4f4;
  border-right-color: #f4f4f4;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type {
  margin-left: 0;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type.active > a {
  border-left-color: transparent;
}
.nav-tabs-custom > .nav-tabs.pull-right {
  float: none !important;
}
.nav-tabs-custom > .nav-tabs.pull-right > li {
  float: right;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type {
  margin-right: 0;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type > a {
  border-left-width: 1px;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type.active > a {
  border-left-color: #f4f4f4;
  border-right-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.header {
  line-height: 35px;
  padding: 0 10px;
  font-size: 20px;
  color: #444;
}
.nav-tabs-custom > .nav-tabs > li.header > .fa,
.nav-tabs-custom > .nav-tabs > li.header > .glyphicon,
.nav-tabs-custom > .nav-tabs > li.header > .ion {
  margin-right: 5px;
}
.nav-tabs-custom > .tab-content {
  background: #fff;
  padding: 10px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.nav-tabs-custom .dropdown.open > a:active,
.nav-tabs-custom .dropdown.open > a:focus {
  background: transparent;
  color: #999;
}
.nav-tabs-custom.tab-primary > .nav-tabs > li.active {
  border-top-color: #1d7f9b;
}
.nav-tabs-custom.tab-info > .nav-tabs > li.active {
  border-top-color: #3c8dbc;
}
.nav-tabs-custom.tab-danger > .nav-tabs > li.active {
  border-top-color: #dd142f;
}
.nav-tabs-custom.tab-warning > .nav-tabs > li.active {
  border-top-color: #f39c12;
}
.nav-tabs-custom.tab-success > .nav-tabs > li.active {
  border-top-color: #00a65a;
}
.nav-tabs-custom.tab-default > .nav-tabs > li.active {
  border-top-color: #d2d6de;
}
/* PAGINATION */
.pagination > li > a {
  background: #fafafa;
  color: #666;
}
.pagination.pagination-flat > li > a {
  border-radius: 0 !important;
}
/*
 * Component: Products List
 * ------------------------
 */
.products-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.products-list > .item {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  background: #fff;
}
.products-list > .item:before,
.products-list > .item:after {
  content: " ";
  display: table;
}
.products-list > .item:after {
  clear: both;
}
.products-list .product-img {
  float: left;
}
.products-list .product-img img {
  width: 50px;
  height: 50px;
}
.products-list .product-info {
  margin-left: 60px;
}
.products-list .product-title {
  font-weight: 600;
}
.products-list .product-description {
  display: block;
  color: #999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product-list-in-box > .item {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid #f4f4f4;
}
.product-list-in-box > .item:last-of-type {
  border-bottom-width: 0;
}
/*
 * Component: Table
 * ----------------
 */
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #f4f4f4;
}
.table > thead > tr > th {
  border-bottom: 2px solid #f4f4f4;
}
.table tr td .progress {
  margin-top: 5px;
}
.table-bordered {
  border: 1px solid #f4f4f4;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #f4f4f4;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table.no-border,
.table.no-border td,
.table.no-border th {
  border: 0;
}
/* .text-center in tables */
table.text-center,
table.text-center td,
table.text-center th {
  text-align: center;
}
.table.align th {
  text-align: left;
}
.table.align td {
  text-align: right;
}
/*
 * Component: Label
 * ----------------
 */
.label-default {
  background-color: #d2d6de;
  color: #444;
}
/*
 * Component: Direct Chat
 * ----------------------
 */
.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0;
}
.direct-chat.chat-pane-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow: auto;
}
.direct-chat-msg,
.direct-chat-text {
  display: block;
}
.direct-chat-msg {
  margin-bottom: 10px;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table;
}
.direct-chat-msg:after {
  clear: both;
}
.direct-chat-messages,
.direct-chat-contacts {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -moz-transition: -moz-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}
.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}
.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}
.right .direct-chat-text:after,
.right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de;
}
.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}
.right .direct-chat-img {
  float: right;
}
.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}
.direct-chat-name {
  font-weight: 600;
}
.direct-chat-timestamp {
  color: #999;
}
.direct-chat-contacts-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.direct-chat-contacts {
  -webkit-transform: translate(101%, 0);
  -ms-transform: translate(101%, 0);
  -o-transform: translate(101%, 0);
  transform: translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #fff;
  overflow: auto;
}
.contacts-list > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
}
.contacts-list > li:before,
.contacts-list > li:after {
  content: " ";
  display: table;
}
.contacts-list > li:after {
  clear: both;
}
.contacts-list > li:last-of-type {
  border-bottom: none;
}
.contacts-list-img {
  border-radius: 50%;
  width: 40px;
  float: left;
}
.contacts-list-info {
  margin-left: 45px;
  color: #fff;
}
.contacts-list-name,
.contacts-list-status {
  display: block;
}
.contacts-list-name {
  font-weight: 600;
}
.contacts-list-status {
  font-size: 12px;
}
.contacts-list-date {
  color: #aaa;
  font-weight: normal;
}
.contacts-list-msg {
  color: #999;
}
.direct-chat-danger .right > .direct-chat-text {
  background: #dd142f;
  border-color: #dd142f;
  color: #fff;
}
.direct-chat-danger .right > .direct-chat-text:after,
.direct-chat-danger .right > .direct-chat-text:before {
  border-left-color: #dd142f;
}
.direct-chat-primary .right > .direct-chat-text {
  background: #1d7f9b;
  border-color: #1d7f9b;
  color: #fff;
}
.direct-chat-primary .right > .direct-chat-text:after,
.direct-chat-primary .right > .direct-chat-text:before {
  border-left-color: #1d7f9b;
}
.direct-chat-warning .right > .direct-chat-text {
  background: #f39c12;
  border-color: #f39c12;
  color: #fff;
}
.direct-chat-warning .right > .direct-chat-text:after,
.direct-chat-warning .right > .direct-chat-text:before {
  border-left-color: #f39c12;
}
.direct-chat-info .right > .direct-chat-text {
  background: #3c8dbc;
  border-color: #3c8dbc;
  color: #fff;
}
.direct-chat-info .right > .direct-chat-text:after,
.direct-chat-info .right > .direct-chat-text:before {
  border-left-color: #3c8dbc;
}
.direct-chat-success .right > .direct-chat-text {
  background: #00a65a;
  border-color: #00a65a;
  color: #fff;
}
.direct-chat-success .right > .direct-chat-text:after,
.direct-chat-success .right > .direct-chat-text:before {
  border-left-color: #00a65a;
}
/*
 * Component: Users List
 * ---------------------
 */
.users-list > li {
  width: 25%;
  float: left;
  padding: 10px;
  text-align: center;
}
.users-list > li img {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
}
.users-list > li > a:hover,
.users-list > li > a:hover .users-list-name {
  color: #999;
}
.users-list-name,
.users-list-date {
  display: block;
}
.users-list-name {
  font-weight: 600;
  color: #444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.users-list-date {
  color: #999;
  font-size: 12px;
}
/*
 * Component: Carousel
 * -------------------
 */
.carousel-control.left,
.carousel-control.right {
  background-image: none;
}
.carousel-control > .fa {
  font-size: 40px;
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -20px;
}
/*
 * Component: modal
 * ----------------
 */
.modal {
  background: rgba(0, 0, 0, 0.3);
}
.modal-content {
  border-radius: 0;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  border: 0;
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  }
}
.modal-header {
  border-bottom-color: #f4f4f4;
}
.modal-header .close {
  height: 2.35294rem !important;
  width: 2.35294rem !important;
  text-align: center;
  padding: 0 !important;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  top: -1.17647rem !important;
  right: -1.17647rem !important;
  background: rgb(232 166 7);
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px 0 #0000001a;
  box-shadow: 0 2px 4px 0 #0000001a;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  color: rgb(255 255 255);
  position: absolute;
  font-size: 24px;
  font-weight: 900;
  opacity: inherit;
}
.modal-footer {
  border-top-color: #f4f4f4;
}
.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: #155c70;
}
.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: #c87f0a;
}
.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: #307095;
}
.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: #00733e;
}
.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: #ae1025;
}
/*
 * Component: Social Widgets
 * -------------------------
 */
.box-widget {
  border: none;
  position: relative;
}
.widget-user .widget-user-header {
  padding: 20px;
  height: 120px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.widget-user .widget-user-username {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 300;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.widget-user .widget-user-desc {
  margin-top: 0;
}
.widget-user .widget-user-image {
  position: absolute;
  top: 65px;
  left: 50%;
  margin-left: -45px;
}
.widget-user .widget-user-image > img {
  width: 90px;
  height: auto;
  border: 3px solid #fff;
}
.widget-user .box-footer {
  padding-top: 30px;
}
.widget-user-2 .widget-user-header {
  padding: 20px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.widget-user-2 .widget-user-username {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 300;
}
.widget-user-2 .widget-user-desc {
  margin-top: 0;
}
.widget-user-2 .widget-user-username,
.widget-user-2 .widget-user-desc {
  margin-left: 75px;
}
.widget-user-2 .widget-user-image > img {
  width: 65px;
  height: auto;
  float: left;
}
.treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 5px;
}
.treeview-menu .treeview-menu {
  padding-left: 20px;
}
.treeview-menu > li {
  margin: 0;
}
.treeview-menu > li > a {
  padding: 5px 5px 5px 15px;
  display: block;
  font-size: 14px;
}
.treeview-menu > li > a > .fa,
.treeview-menu > li > a > .glyphicon,
.treeview-menu > li > a > .ion {
  width: 20px;
}
.treeview-menu > li > a > .pull-right-container > .fa-angle-left,
.treeview-menu > li > a > .pull-right-container > .fa-angle-down,
.treeview-menu > li > a > .fa-angle-left,
.treeview-menu > li > a > .fa-angle-down {
  width: auto;
}
.treeview > ul.treeview-menu {
  overflow: hidden;
  height: auto;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.treeview.menu-open > ul.treeview-menu {
  overflow: visible;
  height: auto;
}
/*
 * Page: Mailbox
 * -------------
 */
.mailbox-messages > .table {
  margin: 0;
}
.mailbox-controls {
  padding: 5px;
}
.mailbox-controls.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.mailbox-read-info {
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
}
.mailbox-read-info h3 {
  font-size: 20px;
  margin: 0;
}
.mailbox-read-info h5 {
  margin: 0;
  padding: 5px 0 0 0;
}
.mailbox-read-time {
  color: #999;
  font-size: 13px;
}
.mailbox-read-message {
  padding: 10px;
}
.mailbox-attachments li {
  float: left;
  width: 200px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  margin-right: 10px;
}
.mailbox-attachment-name {
  font-weight: bold;
  color: #666;
}
.mailbox-attachment-icon,
.mailbox-attachment-info,
.mailbox-attachment-size {
  display: block;
}
.mailbox-attachment-info {
  padding: 10px;
  background: #f4f4f4;
}
.mailbox-attachment-size {
  color: #999;
  font-size: 12px;
}
.mailbox-attachment-icon {
  text-align: center;
  font-size: 65px;
  color: #666;
  padding: 20px 10px;
}
.mailbox-attachment-icon.has-img {
  padding: 0;
}
.mailbox-attachment-icon.has-img > img {
  max-width: 100%;
  height: auto;
}
/*
 * Page: Lock Screen
 * -----------------
 */
/* ADD THIS CLASS TO THE <BODY> TAG */
.lockscreen {
  background: #d2d6de;
}
.lockscreen-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}
.lockscreen-logo a {
  color: #444;
}
.lockscreen-wrapper {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10%;
}
/* User name [optional] */
.lockscreen .lockscreen-name {
  text-align: center;
  font-weight: 600;
}
/* Will contain the image and the sign in form */
.lockscreen-item {
  border-radius: 4px;
  padding: 0;
  background: #fff;
  position: relative;
  margin: 10px auto 30px auto;
  width: 290px;
}
/* User image */
.lockscreen-image {
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: -25px;
  background: #fff;
  padding: 5px;
  z-index: 10;
}
.lockscreen-image > img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
/* Contains the password input and the login button */
.lockscreen-credentials {
  margin-left: 70px;
}
.lockscreen-credentials .form-control {
  border: 0;
}
.lockscreen-credentials .btn {
  background-color: #fff;
  border: 0;
  padding: 0 10px;
}
.lockscreen-footer {
  margin-top: 10px;
}
/*
 * Page: Login & Register
 * ----------------------
 */
.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}
.login-logo a,
.register-logo a {
  color: #444;
}
.login-page,
.register-page {
  height: auto;
  background: #d2d6de;
}
.login-box,
.register-box {
  width: 360px;
  margin: 7% auto;
}
@media (max-width: 768px) {
  .login-box,
  .register-box {
    width: 90%;
    margin-top: 20px;
  }
}
.login-box-body,
.register-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
}
.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: #777;
}
.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}
.social-auth-links {
  margin: 10px 0;
}
/*
 * Page: 400 and 500 error pages
 * ------------------------------
 */
.error-page {
  width: 600px;
  margin: 20px auto 0 auto;
}
@media (max-width: 991px) {
  .error-page {
    width: 100%;
  }
}
.error-page > .headline {
  float: left;
  font-size: 100px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .error-page > .headline {
    float: none;
    text-align: center;
  }
}
.error-page > .error-content {
  margin-left: 190px;
  display: block;
}
@media (max-width: 991px) {
  .error-page > .error-content {
    margin-left: 0;
  }
}
.error-page > .error-content > h3 {
  font-weight: 300;
  font-size: 25px;
}
@media (max-width: 991px) {
  .error-page > .error-content > h3 {
    text-align: center;
  }
}
/*
 * Page: Invoice
 * -------------
 */
.invoice {
  position: relative;
  background: #fff;
  border: 1px solid #f4f4f4;
  padding: 20px;
  margin: 10px 25px;
}
.invoice-title {
  margin-top: 0;
}
/*
 * Page: Profile
 * -------------
 */
.profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border: 3px solid #d2d6de;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
}
.post {
  border-bottom: 1px solid #d2d6de;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #666;
}
.post:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.post .user-block {
  margin-bottom: 15px;
}
/*
 * Plugin: Select2
 * ---------------
 */
.select2-container--default.select2-container--focus,
.select2-selection.select2-container--focus,
.select2-container--default:focus,
.select2-selection:focus,
.select2-container--default:active,
.select2-selection:active {
  outline: none;
}
.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
  border: 1px solid #d2d6de;
  border-radius: 0;
  padding: 6px 12px;
  height: 34px;
}
.select2-container--default.select2-container--open {
  border-color: #1d7f9b;
}
.select2-dropdown {
  border: 1px solid #d2d6de;
  border-radius: 0;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1d7f9b;
  color: white;
}
.select2-results__option {
  padding: 6px 12px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  margin-top: -4px;
}
.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
  padding-right: 6px;
  padding-left: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 28px;
  right: 3px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: 0;
}
.select2-dropdown .select2-search__field,
.select2-search--inline .select2-search__field {
  border: 1px solid #d2d6de;
}
.select2-dropdown .select2-search__field:focus,
.select2-search--inline .select2-search__field:focus {
  outline: none;
}
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #1d7f9b !important;
}
.select2-container--default .select2-results__option[aria-disabled='true'] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected='true'] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option[aria-selected='true'],
.select2-container--default .select2-results__option[aria-selected='true']:hover {
  color: #444;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #d2d6de;
  border-radius: 0;
}
.select2-container--default .select2-selection--multiple:focus {
  border-color: #1d7f9b;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #d2d6de;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #1d7f9b;
  border-color: #196d86;
  padding: 1px 10px;
  color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.7);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 10px;
}
.box .datepicker-inline,
.box .datepicker-inline .datepicker-days,
.box .datepicker-inline > table,
.box .datepicker-inline .datepicker-days > table {
  width: 100%;
}
.box .datepicker-inline td:hover,
.box .datepicker-inline .datepicker-days td:hover,
.box .datepicker-inline > table td:hover,
.box .datepicker-inline .datepicker-days > table td:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.box .datepicker-inline td.day.old,
.box .datepicker-inline .datepicker-days td.day.old,
.box .datepicker-inline > table td.day.old,
.box .datepicker-inline .datepicker-days > table td.day.old,
.box .datepicker-inline td.day.new,
.box .datepicker-inline .datepicker-days td.day.new,
.box .datepicker-inline > table td.day.new,
.box .datepicker-inline .datepicker-days > table td.day.new {
  color: #777;
}
.fullscreen-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  text-align: center;
  z-index: 10000;
}
.loading .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loading .overlay .icon-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.icon-loading {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.icon-loading:before {
  content: '\f021';
}
.certificate .loading {
  position: relative;
  top: -100%;
}
@media (min-width: 750px) {
  .container {
    width: 750px !important;
  }
}
@media (min-width: 970px) {
  .container {
    width: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1360px !important;
  }
}
@media (min-width: 1500px) {
  .container {
    width: 1622px !important;
  }
}
@media (min-width: 750px) {
  .navbar-brand {
    padding: 15px 15px !important;
  }
}
@media (min-width: 970px) {
  .navbar-brand {
    padding: 10px 30px !important;
  }
}
@media (min-width: 1200px) {
  .navbar-brand {
    padding: 10px 30px !important;
  }
}
@media (min-width: 1500px) {
  .navbar-brand {
    padding: 10px 30px !important;
  }
}
@media (min-width: 750px) {
  .modal-dialog {
    width: auto !important;
    margin: 10px !important;
  }
}
@media (min-width: 960px) {
  .modal-dialog {
    width: 1000px !important;
    margin: 30px auto !important;
  }
}
telephone {
  font-family: 'Courier New', Courier, monospace;
  color: #496ec1;
  font-weight: 900;
}
table.dataTable {
  background-color: #fff;
}
table.dataTable thead {
  background-color: #3c8dbc !important;
  color: #ffffff !important;
  border-bottom: 1px solid #000000 !important;
}
table.dataTable thead .sorting:after {
  top: 1px;
}
table.dataTable thead .sorting_asc:after {
  top: 1px;
}
table.dataTable thead .sorting_desc:after {
  top: 1px;
}
table.dataTable thead td,
table.dataTable thead th {
  padding: 0 18px 2px !important;
  border-right: 1px solid #a1a1a1 !important;
}
table.dataTable tfoot {
  background-color: #3c8dbc !important;
  color: #ffffff !important;
  border-top: 1px solid #000000 !important;
}
table.dataTable tfoot td,
table.dataTable tfoot th {
  padding: 0 18px 2px !important;
  border-right: 1px solid #a1a1a1 !important;
}
table.dataTable tbody td,
table.dataTable tbody th {
  padding: 6px 10px !important;
  border-top: 0.1px solid #ebeaea !important;
  border-right: 0.1px solid #ebeaea !important;
}
table.dataTable tbody td code,
table.dataTable tbody th code {
  font-weight: 800;
}
table.dataTable .hover tbody tr:hover {
  background-color: red;
}
.certificate-table-content table.dataTable thead {
  background-color: #3c9c86 !important;
}
.certificate-table-content table.dataTable tfoot {
  background-color: #3c9c86 !important;
}
.agent-table-content table.dataTable thead {
  background-color: #bd3740 !important;
}
.agent-table-content table.dataTable tfoot {
  background-color: #bd3740 !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fade {
  transition: opacity 0.005s linear !important;
}
.fixed-table-container {
  border: 0 !important;
  border-radius: 0 !important;
}
.wizard .content {
  overflow: scroll;
  background: 0 !important;
  border: 1px solid #eee !important;
  border-radius: 0 !important;
  min-height: 24em !important;
}
.wizard .content .body {
  width: 100% !important;
}
.wizard .content .body label.error {
  margin: 0 !important;
  font-weight: 500 !important;
}
.wizard .steps a,
.wizard .steps a:hover,
.wizard .steps a:active {
  border-radius: 0px !important;
  padding: 0.3em 1em !important;
}
.wizard .form-control-uppercase {
  text-transform: uppercase;
}
.wizard .form-control-bold {
  font-weight: 600;
}
.wizard .form-control-sm {
  padding: 0.25rem 0.5rem;
}
.wizard .upper_five {
  margin-bottom: 0;
  color: #26549a;
  font-weight: 600;
  text-transform: uppercase;
}
#modal-add-client .wizard .steps ul > li {
  width: 25% !important;
}
#modal-add-cert .wizard .steps ul > li {
  width: 20% !important;
}
#modal-add-vehicle .wizard .steps ul > li {
  width: 16.66% !important;
}
#modal-add-certificate .wizard .steps ul > li {
  width: 50% !important;
}
.swal2-input {
  text-align: center;
}
.certificate #modal-view-data .modal-content .loading .overlay {
  position: relative;
  top: -100%;
}
.client #modal-view-data .modal-content .loading .overlay {
  position: relative;
  top: -100%;
}
.realtime #fuel-modal .modal-content .loading .overlay {
  position: relative;
  top: -100%;
}
.common-img-bg {
  background-size: cover;
  background: url(../images/bg_login.jpg) no-repeat center center fixed;
  height: 100%;
}
.small_icon_loader {
  display: block;
  position: absolute;
  bottom: 85px;
  left: 50%;
  font-family: Arial;
  max-width: 178px;
  font-size: 12px;
  line-height: 19px;
  z-index: 1;
  color: #6c7486;
}
.small_text_loader {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  font-family: Arial;
  max-width: 320px;
  font-size: 12px;
  line-height: 19px;
  z-index: 1;
  color: #6c7486;
}
@media (min-width: 481px) {
  .small_text_loader {
    transform: translateX(-50%);
    text-align: center;
  }
  .small_icon_loader {
    transform: translateX(-50%);
    text-align: center;
  }
}
@media (max-width: 480px) {
  .small_text_loader {
    transform: translateX(-50%);
    text-align: center;
    font-size: 10px;
    line-height: 17px;
    /* transform: translateZ(400px); */
  }
  .small_icon_loader {
    /* left: 20px;
				right: 112px; */
    bottom: 91px;
    font-size: 10px;
    transform: translateX(-50%);
    text-align: center;
    line-height: 17px;
    /* transform: translateZ(400px); */
  }
}
.login-section-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.darkRedClass {
  background-color: #dd9696;
}
.redClass {
  background-color: #f1cbcb;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
html {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
body {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
a.address {
  text-wrap: normal;
}
a.address:hover {
  cursor: pointer;
}
p.address {
  white-space: pre-wrap;
  font-size: 14px;
  color: #337ab7;
}
a > i {
  padding-right: 5px;
}
input.has-error {
  border-color: #dd4b39;
  box-shadow: none;
}
.dropdown-menu a:hover {
  cursor: pointer;
}
.validate-form .form-group {
  height: 56px;
}
.tooltip-inner {
  max-width: 1000px;
  text-align: left;
}
.bootstrap-table .table:not(.table-condensed) {
  padding: 0px 8px;
}
.bootstrap-table .table:not(.table-condensed) > tbody > tr > th {
  padding: 0px 8px;
}
.bootstrap-table .table:not(.table-condensed) > tbody > tr > td {
  padding: 0px 8px;
}
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > th {
  padding: 0px 8px;
}
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > td {
  padding: 0px 8px;
}
.bootstrap-table .table:not(.table-condensed) > thead > tr > td {
  padding: 0px 8px;
}
.bootstrap-table .table thead > tr > th {
  padding: 0;
  margin: 0;
  background-color: #3c8dbc;
  color: #ffffff;
}
.bootstrap-table .fixed-table-container thead th .th-inner {
  padding: 0px 8px;
}
.bootstrap-table .fixed-table-container tbody td .th-inner {
  padding: 0px 8px;
}
.bootstrap-table .center {
  text-align: center;
}
.bootstrap-table tbody tr.selected {
  background-color: #b0bed9;
}
.bootstrap-table tbody td {
  font-size: 14px;
}
.bootstrap-dialog .modal-header.bootstrap-dialog-draggable {
  cursor: move;
}
.bootstrap-dialog .bootstrap-dialog-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.ztree li a i {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ztree li a .icon-car {
  line-height: 21px;
  font-family: Verdana, Arial, Helvetica, AppleGothic, FontAwesome, sans-serif;
}
.ztree li a .icon-deviceGroup {
  line-height: 21px;
  font-family: Verdana, Arial, Helvetica, AppleGothic, Glyphicons Halflings, sans-serif;
}
.ztree li .toolbar {
  display: inline;
  float: right;
  padding-right: 5px;
}
.ztree li .toolbar .status {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0px;
}
.ztree li .toolbar .status > i {
  padding: 0px 5px;
}
.ztree li .toolbar .icon-option-vertical {
  line-height: 21px;
  color: #0087ff;
}
.ztree li .toolbar .btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0px;
}
.ztree li .toolbar .dropdown-menu {
  left: auto;
  right: 0px;
  padding: 0px;
  margin: 0px;
}
.ztree li .toolbar .dropdown-menu > li > a {
  padding: 5px 10px;
  height: 30px;
  width: 100%;
  text-decoration: none;
}
.ztree a > i {
  padding-right: 5px;
}
.btn-app > .icon {
  font-size: 20px;
  display: block;
}
.timeline > li > .icon {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}
.timeline .icon-parking {
  background: red;
  color: #ffffff;
}
.timeline .icon-trip {
  background: #3c8dbc;
  color: #ffffff;
}
.bv-form .form-control-feedback {
  position: absolute;
  top: 10px;
}
.bv-form .form-control-feedback.icon-loading {
  width: 14px;
  height: 14px;
  right: 25px;
}
.icon-search {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-search:before {
  content: '\e003';
}
.icon-deviceGroup {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-deviceGroup:before {
  content: '\e021';
}
.icon-columns {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-columns:before {
  content: '\e011';
}
.icon-zoomIn {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-zoomIn:before {
  content: '\e015';
}
.icon-zoomOut {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-zoomOut:before {
  content: '\e016';
}
.icon-option-vertical {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-option-vertical:before {
  content: '\e235';
}
.icon-recycle {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-recycle:before {
  content: '\e020';
}
.icon-delete {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-delete:before {
  content: '\e020';
}
.icon-edit {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-edit:before {
  content: '\270f';
}
.icon-valid {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-valid:before {
  content: '\e013';
}
.icon-invalid {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-invalid:before {
  content: '\e014';
}
.icon-plus {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-plus:before {
  content: '\002b';
}
.icon-minus {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-minus:before {
  content: '\2212';
}
.icon-expand {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-expand:before {
  content: '\e118';
}
.icon-collapse {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-collapse:before {
  content: '\e117';
}
.icon-transfer {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-transfer:before {
  content: '\e178';
}
.icon-car {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-car:before {
  content: '\f1b9';
}
.icon-collapse-down {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #337ab7;
}
.icon-collapse-down:before {
  content: '\f103';
}
.icon-collapse-left {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #337ab7;
}
.icon-collapse-left:before {
  content: '\f100';
}
.icon-collapse-right {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #337ab7;
}
.icon-collapse-right:before {
  content: '\f101';
}
.icon-cluster {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-cluster:before {
  content: '\f140';
}
.icon-poi-icon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-poi-icon:before {
  content: '\f1c5';
}
.icon-poi-label {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-poi-label:before {
  content: '\f035';
}
.icon-fitMap {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-fitMap:before {
  content: '\f247';
}
.icon-stopDrawing {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-stopDrawing:before {
  content: '\f256';
}
.icon-geofence-circle {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-geofence-circle:before {
  content: '\f1db';
}
.icon-geofence-rectangle {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-geofence-rectangle:before {
  content: '\f096';
}
.icon-alarm {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-alarm:before {
  content: '\f0a2';
}
.icon-message {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-message:before {
  content: '\f003';
}
.icon-dashboard {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-dashboard:before {
  content: '\f0e4';
}
.icon-loading {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.icon-loading:before {
  content: '\f021';
}
.icon-time {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-time:before {
  content: '\f017';
}
.icon-resendCommand {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-resendCommand:before {
  content: '\f05a';
}
.icon-map {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-map:before {
  content: '\f278';
}
.icon-play {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-play:before {
  content: '\f04b';
}
.icon-pause {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pause:before {
  content: '\f04c';
}
.icon-reset {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-reset:before {
  content: '\f01e';
}
.icon-bind {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bind:before {
  content: '\f0c1';
}
.icon-unbind {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-unbind:before {
  content: '\f127';
}
.icon-handRight {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-handRight:before {
  content: '\f0a4';
}
.icon-bound-select {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bound-select:before {
  content: '\f096';
}
.icon-configuration {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-configuration:before {
  content: '\f085';
}
.icon-save-map-view {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-save-map-view:before {
  content: '\f0ac';
}
.icon-show {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-show:before {
  content: '\f06e';
}
.icon-hide {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-hide:before {
  content: '\f070';
}
.icon-reportItem {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-circle-check {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-circle-check:before {
  content: '\f058';
}
.icon-users {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-users:before {
  content: '\f0c0';
}
.icon-map-marker {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-map-marker:before {
  content: '\f041';
}
.icon-truck {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-truck:before {
  content: '\f0d1';
}
.icon-table-edit {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-table-edit:before {
  content: '\f044';
}
.icon-circle-check.check {
  color: green;
}
.icon-circle-check.uncheck {
  color: grey;
}
.treeview-menu > li > a > i:before {
  content: '\f10c';
}
.treeview-menu > li.active > a > i:before {
  content: '\f192';
}
.icon-geofence-polyline {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-geofence-polyline:before {
  content: '\e625';
}
.icon-geofence-polygon {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-geofence-polygon:before {
  content: '\e658';
}
.icon-geofence {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-geofence:before {
  content: '\e61a';
}
.icon-poi {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-poi:before {
  content: '\e631';
}
.icon-engine {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-engine:before {
  content: '\e7ef';
}
.icon-satellite {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-satellite:before {
  content: '\e768';
}
.icon-historyReplay {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-historyReplay:before {
  content: '\e61c';
}
.icon-multi-screen {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-multi-screen:before {
  content: '\e71b';
}
.icon-report {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report:before {
  content: '\e7d4';
}
.icon-system {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-system:before {
  content: '\e637';
}
.icon-fullscreen {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-fullscreen:before {
  content: '\e65b';
}
.icon-exit-fullscreen {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-exit-fullscreen:before {
  content: '\e638';
}
.icon-traffic {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-traffic:before {
  content: '\ed6f';
}
.icon-ignition {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-ignition:before {
  content: '\ef59';
}
.icon-parking {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-parking:before {
  content: '\e836';
}
.icon-signal-wire {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-signal-wire:before {
  content: '\efc4';
}
.icon-case {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-case:before {
  content: '\eec1';
}
.icon-trip {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-trip:before {
  content: '\e641';
}
.icon-command {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-command:before {
  content: '\e6ad';
}
.icon-map-layer {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-map-layer:before {
  content: '\e665';
}
.icon-report-menu-driving {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-driving:before {
  content: '\e616';
}
.icon-report-menu-common {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-common:before {
  content: '\e6a5';
}
.icon-report-menu-alarm {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-alarm:before {
  content: '\e601';
}
.icon-report-menu-geofence {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-geofence:before {
  content: '\e64b';
}
.icon-report-menu-fuel {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-fuel:before {
  content: '\e618';
}
.icon-report-menu-rfid {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-rfid:before {
  content: '\e701';
}
.icon-report-menu-peripherals {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-peripherals:before {
  content: '\e617';
}
.icon-report-menu-custom {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-report-menu-custom:before {
  content: '\e640';
}
.icon-load-address {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-load-address:before {
  content: '\e66b';
}
.icon-battery {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-battery:before {
  content: '\f4ef';
  color: gray;
}
.icon-motor {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-motor:before {
  content: '\e62b';
  color: gray;
}
.icon-lock {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-lock:before {
  content: '\eda0';
  color: gray;
}
.icon-cover {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-cover:before {
  content: '\e609';
  color: gray;
}
.icon-battery.state-battery-0:before {
  content: '\f4f4';
  color: orange;
  font-size: 22px;
}
.icon-battery.state-battery-1:before {
  content: '\f4ef';
  color: red;
}
.icon-battery.state-battery-2:before {
  content: '\f4f0';
  color: green;
}
.icon-battery.state-battery-3:before {
  content: '\f4f3';
  color: green;
}
.icon-battery.state-battery-4:before {
  content: '\f4f1';
  color: green;
}
.icon-battery.state-battery-5:before {
  content: '\f4f2';
  color: green;
}
.icon-motor.state-fault:before {
  color: red;
}
.icon-motor.state-normal:before {
  color: green;
}
.icon-lock.state-lock:before {
  content: '\eda0';
  color: green;
}
.icon-lock.state-unlock:before {
  content: '\e68a';
  color: red;
}
.icon-cover.state-open:before {
  content: '\e607';
  color: red;
}
.icon-cover.state-close:before {
  content: '\e60c';
  color: green;
}
.icheck-tile {
  width: 100%;
  overflow: auto;
}
.icheck-tile > label {
  margin-left: 10px;
  width: 23%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icheck-tile > label > div {
  position: relative;
  margin-right: 5px;
}
.info {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url('/static/dist/roadsmart/images/info.png');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  overflow: hidden;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.fullscreen-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  text-align: center;
  z-index: 10000;
}
.loading .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loading .overlay .icon-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.main-content {
  height: -moz-calc(49%);
  height: -webkit-calc(49%);
  height: calc(100% - 51px);
  width: 100%;
}
.easyui-layout .content {
  padding-left: 0px;
  padding-right: 0px;
}
.xm-panel {
  width: 100%;
  height: 100%;
}
.map {
  width: 100%;
  height: 100%;
}
.mt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: normal;
  overflow: hidden;
}
.columns {
  margin-left: 5px;
  line-height: 34px;
}
.icheck-radio-group {
  display: inline-flex;
  height: 34px;
  line-height: 34px;
  justify-content: space-between;
  align-items: center;
}
.icheck-radio-group > label {
  margin-left: 10px;
}
.icheck-radio-group > label > .iradio_minimal-blue {
  position: relative;
  margin-right: 5px;
}
.icheck-checkbox-group {
  display: inline-flex;
  height: 34px;
  line-height: 34px;
  justify-content: space-between;
  align-items: center;
}
.icheck-checkbox-group > label {
  margin-left: 10px;
}
.icheck-checkbox-group > label > div {
  position: relative;
  margin-right: 5px;
}
.icheck-vertical-group > label {
  margin-bottom: 10px;
  width: 90%;
}
.icheck-vertical-group > label > div {
  position: relative;
  margin-right: 5px;
}
.icheck-checkbox-vertical-group > label {
  margin-bottom: 10px;
  width: 90%;
}
.icheck-checkbox-vertical-group > label > div {
  position: relative;
  margin-right: 5px;
}
.mt-map-info td.title {
  width: 150px;
}
.mt-map-info td.content {
  word-wrap: break-word;
}
.map-container {
  width: 100%;
  height: 100%;
}
.map-container .map-controls {
  position: absolute;
  top: 0px;
  right: 0px;
}
.map-container .map-controls .btn-group-vertical {
  display: block;
  margin-bottom: 10px;
}
.map-container .map-controls .btn-group-horizontal {
  display: inline-flex;
  margin-left: 10px;
  vertical-align: middle;
}
.map-container .map-controls button {
  padding: 4px 8px;
  height: 35px;
}
.map-container .map-controls button > i {
  font-size: 20px;
  line-height: 24px;
}
.map-container .map-controls button > .icon-geofence-polygon {
  font-size: 24px;
  font-weight: bold;
}
.map-container .map-controls button > .icon-geofence-polyline {
  font-size: 18px;
  font-weight: bold;
}
.map-container .map-controls button.active {
  background-color: #1b99bd;
  color: #ffffff;
}
.mt-infoWindow {
  width: 100%;
}
.mt-infoWindow tbody > tr > td {
  padding: 3px;
  border-top: 0px;
}
.mt-infoWindow .title {
  font-weight: bold;
  text-align: right;
  width: 130px;
}
.mt-infoWindow .content {
  word-break: break-all;
}
.infoWindow-device-content {
  width: 430px;
  border: 2px inset #3c8dbc;
  border-radius: 4px;
  cursor: default;
}
.infoWindow-device-content table {
  margin-bottom: 0px;
}
.infoWindow-device-content table > tbody > tr > td {
  padding: 3px;
  border-top: 0px;
}
.infoWindow-device-content table td.title {
  font-weight: bold;
  text-align: right;
  width: 130px;
}
.infoWindow-device-content table td.status_title {
  font-weight: bold;
  text-align: right;
  width: 130px;
  vertical-align: middle;
}
.infoWindow-device-content table td.content {
  word-break: break-all;
}
.infoWindow-device-content table td.status {
  vertical-align: middle;
}
.infoWindow-device-content table td.status i {
  padding-right: 3px;
}
.infoWindow-device-title span {
  padding-left: 5px;
}
.infoWindow-device-function {
  padding: 6px 0px 6px 0px;
}
.infoWindow-device-function i {
  cursor: pointer;
  font-size: 24px;
  padding-left: 6px;
}
.poi-title-parking {
  display: inline-block;
  background-color: red;
  font-size: 15px;
}
.poi-title-parking .index {
  display: inline-block;
  background-color: green;
  color: white;
  padding: 0px 5px 0 5px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}
.poi-title-parking .content {
  display: inline-block;
  color: white;
  min-height: 0px;
}
.poi-title-idling {
  display: inline-block;
  background-color: red;
  font-size: 15px;
}
.poi-title-idling .index {
  display: inline-block;
  background-color: green;
  color: white;
  padding: 0px 5px 0 5px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}
.poi-title-idling .content {
  display: inline-block;
  color: white;
  min-height: 0px;
}
.poi-title-alarm {
  display: inline-block;
  background-color: red;
  font-size: 15px;
}
.poi-title-alarm .index {
  display: inline-block;
  background-color: green;
  color: white;
  padding: 0px 5px 0 5px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}
.poi-title-alarm .content {
  display: inline-block;
  color: white;
  min-height: 0px;
}
.fleet .panel-body {
  font-size: 14px;
}
.fleet #map_info {
  width: 100%;
  height: 100%;
}
.fleet #map_info_close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.fleet #toolbar {
  display: inline-block;
  padding-left: 10px;
  padding-top: 5px;
  overflow: hidden;
}
.fleet td.vehicleName:hover {
  cursor: pointer;
}
.fleet td.vehicleName a {
  text-wrap: normal;
  color: #ffffff;
}
.fleet td.vehicleName .icon-handRight.active {
  display: inline-block;
}
.fleet td.vehicleName .icon-handRight {
  display: none;
}
.fleet #layout_device_tree .nav-tabs-custom {
  height: -moz-calc(55%);
  height: -webkit-calc(55%);
  height: calc(100% - 45px);
  margin-bottom: 0px;
}
.fleet #layout_device_tree .nav-tabs-custom .tab-content {
  height: 100%;
  padding: 0px;
}
.fleet #layout_device_tree .nav-tabs-custom .tab-content .tab-pane {
  height: 100%;
}
.fleet #dd_alarm .menu > li > a p {
  margin: 0px;
}
.fleet #dd_alarm .menu > li > a .menu-item-title {
  font-weight: bold;
}
.fleet #dd_alarm .menu > li > a .menu-item-title span {
  color: #999999;
  font-size: 10px;
  font-weight: normal;
}
.fleet #dd_alarm .menu > li > a .form-group.active {
  display: block;
}
.fleet #dd_alarm .menu > li > a .form-group {
  display: none;
}
.fleet #dd_alarm .menu > li > a button.active {
  display: inline-block;
  margin-right: 10px;
}
.fleet #dd_alarm .menu > li > a button {
  display: none;
}
.fleet .vehicleDashboard {
  height: 100%;
  overflow-y: auto;
}
.fleet .vehicleDashboard > .row {
  margin-left: -15px;
  margin-right: -15px;
}
.fleet .vehicleDashboard > .row > label {
  padding-left: 0px;
}
.fleet .vehicleDashboard .banner {
  font-weight: bold;
  color: #ffffff;
  background-color: #3c8dbc;
}
.fleet .vehicleDashboard .banner > label {
  margin-top: 5px;
  margin-bottom: 5px;
}
.historyReplay #map_info {
  width: 100%;
  height: 100%;
}
.historyReplay #map_info_close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.historyReplay .form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
.historyReplay .form-group {
  margin-bottom: 5px;
}
.historyReplay .input-group-addon > label {
  margin-bottom: 0px;
}
.historyReplay .input-group-addon > label > div {
  position: relative;
  margin-right: 10px;
}
.historyReplay .input-group .spinner {
  width: 0px;
}
.historyReplay #toolbar {
  display: inline-block;
  padding-left: 10px;
  padding-top: 5px;
  overflow: hidden;
}
.historyReplay #layout_condition .icheck-vertical-group {
  display: inline-block;
  margin-top: 5px;
  position: absolute;
  right: 0px;
}
.historyReplay .map-container .marker-checkbox-panel {
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  left: 0px;
}
.command-chat .modal-dialog {
  width: 1000px;
}
.command-chat .bootstrap-dialog-message {
  width: auto;
  height: 600px;
}
.command-chat .direct-chat .direct-chat-history {
  text-align: center;
}
.command-chat .direct-chat .direct-chat-history > a:hover {
  cursor: pointer;
}
.command-chat .direct-chat-messages {
  height: 510px;
  width: auto;
}
.command-chat .direct-chat-text {
  word-break: break-all;
}
.command-chat .direct-chat-builtIn {
  width: 95%;
  margin-bottom: 10px;
}
.command-chat .direct-chat-builtIn .form-group {
  margin-bottom: 5px;
}
.command-chat .direct-chat-canbus {
  width: 100%;
  margin-bottom: 10px;
}
.command-chat .direct-chat-canbus .direct-chat-canbus-checkbox-group {
  width: 100%;
}
.command-chat .direct-chat-canbus .direct-chat-canbus-checkbox-group > label {
  margin-left: 10px;
  width: 30%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.command-chat .direct-chat-canbus .direct-chat-canbus-checkbox-group > label > div {
  position: relative;
  margin-right: 5px;
}
.command-chat .vehicleTree {
  height: 600px;
}
.vehicleSelector {
  width: auto;
  height: 500px;
  overflow: auto;
}
.geofence-management .modal-dialog {
  width: 1200px;
}
.geofence-management .bootstrap-dialog-message {
  width: auto;
  height: 600px;
}
.geofence-management .content {
  background-color: #ffffff;
}
.geofence-management table {
  table-layout: fixed;
}
.geofence-management .radio-group {
  padding-top: 7px;
}
.geofence-management .radio-group > label {
  margin-left: 10px;
}
.geofence-management .radio-group > label > div {
  position: relative;
  margin-right: 10px;
}
.poi-management .modal-dialog {
  width: 1200px;
}
.poi-management .bootstrap-dialog-message {
  width: auto;
  height: 600px;
}
.poi-management .content {
  background-color: #ffffff;
}
.poi-management table {
  table-layout: fixed;
}
.poi-management .tab {
  height: 150px;
  border: 1px solid #f4f4f4;
}
.poi-management .nav-tabs li a:hover {
  opacity: 0.8;
}
.poi-management .tab-content {
  height: 115px;
}
.poi-management .tab-pane {
  height: 100%;
  overflow: auto;
}
.poi-management .tab-pane li {
  list-style-type: none;
  display: block;
  padding: 5px;
  width: 80px;
  height: 50px;
  float: left;
}
.poi-management .tab-pane li.active {
  background-color: Gray;
}
.bound-select-vehicles .modal-dialog {
  width: 1200px;
}
.bound-select-vehicles .bootstrap-dialog-message {
  width: auto;
  height: 600px;
}
.bound-select-vehicles .content {
  background-color: #ffffff;
}
.map-view-configuration .modal-dialog {
  width: 1200px;
}
.map-view-configuration .bootstrap-dialog-message {
  width: auto;
  height: 600px;
}
.map-view-configuration .content {
  background-color: #ffffff;
}
.map-view-configuration .map {
  width: 1150px;
  height: 520px;
}
.notification-configuration .modal-dialog {
  width: 800px;
}
.notification-configuration .bootstrap-dialog-message {
  width: auto;
  height: 600px;
}
.notification-configuration .content {
  background-color: #ffffff;
}
.notification-configuration .tab-content {
  height: 500px;
}
.notification-configuration .tab-content .tab-pane {
  height: 100%;
}
.notification-configuration .tab-content .tab-pane .box {
  height: 100%;
}
.notification-configuration .tab-content .tab-pane .box-body {
  height: -moz-calc(45%);
  height: -webkit-calc(45%);
  height: calc(100% - 55px);
}
.notification-configuration .tab-content .tab-pane .box-body > div {
  height: 100%;
}
.notification-configuration .icheck-tile {
  border: 1px inset #3c8dbc;
  height: 100%;
}
.notification-configuration .icheck-tile > label {
  width: 95%;
}
.load-sensor-configuration-management .modal-dialog {
  width: 800px;
}
.load-sensor-configuration-management .bootstrap-dialog-message {
  width: auto;
  height: 520px;
}
.load-sensor-configuration-management .content {
  background-color: #ffffff;
}
.popup-alarm-choose .modal-dialog {
  width: 1000px;
}
.popup-alarm-choose .bootstrap-dialog-message {
  width: auto;
  height: 520px;
}
.popup-alarm-choose .content {
  background-color: #ffffff;
}
.popup-alarm-choose .alarm-group {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
.popup-alarm-choose .alarm-group > label {
  margin-left: 10px;
  width: 22%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.popup-alarm-choose .alarm-group > label > div {
  position: relative;
  margin-right: 5px;
}
.vehicleTree {
  height: 100%;
}
.vehicleTree .vehicleTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.vehicleTree .vehicleTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.driverTree {
  height: 100%;
}
.driverTree .driverTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.driverTree .driverTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.userTree {
  height: 100%;
}
.userTree .userTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.userTree .userTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.geofenceTree {
  height: 100%;
}
.geofenceTree .geofenceTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.geofenceTree .geofenceTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.truckTree {
  height: 100%;
}
.truckTree .truckTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.truckTree .truckTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.thingTree {
  height: 100%;
}
.thingTree .thingTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.thingTree .thingTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.trailerTree {
  height: 100%;
}
.trailerTree .trailerTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.trailerTree .trailerTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.clientTree {
  height: 100%;
}
.clientTree .clientTree-toolbar {
  height: 34px;
  line-height: 34px;
  width: 100%;
}
.clientTree .clientTree-content {
  width: 100%;
  height: -moz-calc(66%);
  height: -webkit-calc(66%);
  height: calc(100% - 34px);
  overflow: auto;
}
.report .report-tabs > .tab-content > .tab-pane {
  width: 100%;
  height: 100%;
}
.report .report-tabs > .nav-tabs {
  height: 31px;
  overflow: hidden;
}
.report .clearfix,
.report .content {
  margin-bottom: 5px;
}
.report .report-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.report .report-content .input-tree.shown {
  position: absolute;
  width: 327px;
  height: 500px;
  background-color: #ffffff;
  z-index: 999;
  border-bottom: 1px solid #66afe9;
  border-left: 1px solid #66afe9;
  border-right: 1px solid #66afe9;
}
.report .report-content .input-tree.hidden {
  position: absolute;
  display: none;
}
.report .report-content .condition-container {
  height: 34px;
}
.report .report-content .condition-container .input-group {
  width: 400px;
}
.report .report-content .chart-container.collapse {
  display: block;
}
.report .report-content .chart-container.collapse > button {
  margin-bottom: 5px;
}
.report .report-content .chart-container.collapse .chart {
  display: none;
}
.report .report-content .chart-container.expand {
  display: block;
}
.report .report-content .chart-container.expand > button {
  display: none;
}
.report .report-content .chart-container.expand .chart {
  height: 250px;
}
.report .report-content .table-container.collapse {
  display: block;
}
.report .report-content .table-container.collapse .bootstrap-table {
  display: none;
}
.report .report-content .table-container.collapse .toolbar {
  display: none;
}
.report .report-content .table-container.expand {
  display: block;
}
.report .report-content .table-container.expand > button {
  display: none;
}
.report .report-content .table-container .toolbar {
  height: 40px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin-bottom: -10px;
}
.report .report-content .table-container .toolbar .search-text-group {
  width: 250px;
  padding-left: 5px;
}
.report .report-content .row-container {
  overflow-y: auto;
}
.report .report-content .row-container .daily-useage-container {
  width: 95%;
  height: 120px;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d2d6de;
  margin: 15px;
}
.report .report-content .row-container .temperature-container {
  width: 95%;
  height: 250px;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d2d6de;
  margin: 15px;
}
.report .report-content.loading .overlay {
  position: relative;
  top: -100%;
}
.report .report-content.no-chart .chart-container {
  display: none;
}
.report .report-content.no-table .table-container {
  display: none;
}
.daily-useage-container .title {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}
.daily-useage-container .chart {
  width: 100%;
  height: 100px;
  margin-top: -30px;
  margin-bottom: -10px;
  position: relative;
}
.daily-useage-container .footer {
  position: relative;
  width: 100%;
}
.temperature-container .chart {
  width: 100%;
  height: 100%;
}
.system .mt-tabs > .tab-content > .tab-pane {
  width: 100%;
  height: 100%;
}
.system .mt-tabs > .nav-tabs {
  height: 31px;
  overflow: hidden;
}
.system .clearfix,
.system .content {
  margin-bottom: 5px;
}
.system .system-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.system .system-content.loading .overlay {
  position: relative;
  top: -100%;
}
.chart-columns > label {
  margin-left: 10px;
  width: 23%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.chart-columns > label > div {
  margin-right: 5px;
  position: relative;
}
.popup-menu-list {
  display: none;
  position: absolute;
  list-style: none;
  text-align: left;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.popup-menu-list .popup-menu-list-base {
  overflow: auto;
  padding: 10px 10px 0px 10px;
}
.popup-menu-list.open {
  display: block;
}
.popup-menu-list.open:before {
  top: 9px;
  right: -8px;
  border: 8px solid transparent;
  border-left-color: #d9d9d9;
  border-right: 0;
  bottom: auto;
  left: auto;
  content: '';
  display: inline-block;
  position: absolute;
}
.popup-menu-list.open:after {
  top: 10px;
  right: -7px;
  border: 7px solid transparent;
  border-left-color: #fff;
  border-right: 0;
  bottom: auto;
  left: auto;
  content: '';
  display: inline-block;
  position: absolute;
}
.popup-menu-list-base > li > label > .icheckbox_minimal-blue {
  position: relative;
  margin-right: 5px;
}
.popup-menu.open > .btn-default {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.popup-menu.open .popup-menu-btn {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  outline: 0;
}
div.fullscreen_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
div.fullscreen_loader p {
  font-family: 'Helvetica', 'Arial', sans-serif;
  margin: 1em 0 0 0;
  font-size: 16px;
}
div.logo {
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  position: relative;
  background-color: white;
}
div.logo::before,
div.logo::after {
  z-index: 1;
  box-sizing: border-box;
  content: '';
  position: absolute;
  border: 4px solid transparent;
  width: 0;
  height: 0;
  animation-direction: alternate;
  animation-timing-function: linear;
}
div.logo::before {
  top: 0;
  left: 0;
  animation: border-before 1.5s infinite;
  animation-direction: alternate;
}
div.logo::after {
  bottom: 0;
  right: 0;
  animation: border-after 1.5s infinite;
  animation-direction: alternate;
}
div.logo > div {
  position: absolute;
  opacity: 0;
}
div.logo div.white {
  border-left: 4px solid black;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  animation: white 1.5s infinite;
  animation-direction: alternate;
}
div.logo div.orange {
  border-top: 4px solid black;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0;
  background-color: #f3b93f;
  animation: orange 1.5s infinite;
  animation-direction: alternate;
}
div.logo div.red {
  border-right: 4px solid black;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #ea5664;
  animation: red 1.5s infinite;
  animation-direction: alternate;
}
@keyframes border-before {
  0% {
    width: 0;
    height: 0;
    border-top-color: black;
    border-right-color: transparent;
  }
  12.49% {
    border-right-color: transparent;
  }
  12.5% {
    height: 0;
    width: 100%;
    border-top-color: black;
    border-right-color: black;
  }
  25%,
  100% {
    width: 100%;
    height: 100%;
    border-top-color: black;
    border-right-color: black;
  }
}
@keyframes border-after {
  0%,
  24.99% {
    width: 0;
    height: 0;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  25% {
    width: 0;
    height: 0;
    border-left-color: transparent;
    border-bottom-color: black;
  }
  37.49% {
    border-left-color: transparent;
    border-bottom-color: black;
  }
  37.5% {
    height: 0;
    width: 100%;
    border-left-color: black;
    border-bottom-color: black;
  }
  50%,
  100% {
    width: 100%;
    height: 100%;
    border-left-color: black;
    border-bottom-color: black;
  }
}
@keyframes red {
  0%,
  50% {
    width: 0;
    opacity: 0;
  }
  50.01% {
    opacity: 1;
  }
  65%,
  100% {
    opacity: 1;
    width: 27%;
  }
}
@keyframes orange {
  0%,
  65% {
    height: 0;
    opacity: 0;
  }
  65.01% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 1;
    height: 50%;
  }
}
@keyframes white {
  0%,
  75% {
    width: 0;
    opacity: 0;
  }
  75.01% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 1;
    width: 23%;
  }
}
/*
 * General: Miscellaneous
 * ----------------------
 */
.pad {
  padding: 10px;
}
.margin {
  margin: 10px;
}
.margin-bottom {
  margin-bottom: 20px;
}
.margin-bottom-none {
  margin-bottom: 0;
}
.margin-r-5 {
  margin-right: 5px;
}
.inline {
  display: inline;
}
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}
.description-block.margin-bottom {
  margin-bottom: 25px;
}
.description-block > .description-header {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}
.description-block > .description-text {
  text-transform: uppercase;
}
.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active,
.callout.callout-danger,
.callout.callout-warning,
.callout.callout-info,
.callout.callout-success,
.alert-success,
.alert-danger,
.alert-error,
.alert-warning,
.alert-info,
.label-danger,
.label-info,
.label-warning,
.label-primary,
.label-success,
.modal-primary .modal-body,
.modal-primary .modal-header,
.modal-primary .modal-footer,
.modal-warning .modal-body,
.modal-warning .modal-header,
.modal-warning .modal-footer,
.modal-info .modal-body,
.modal-info .modal-header,
.modal-info .modal-footer,
.modal-success .modal-body,
.modal-success .modal-header,
.modal-success .modal-footer,
.modal-danger .modal-body,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  color: #fff !important;
}
.bg-gray {
  color: #000;
  background-color: #d2d6de !important;
}
.bg-gray-light {
  background-color: #f7f7f7;
}
.bg-black {
  background-color: #111 !important;
}
.bg-red,
.callout.callout-danger,
.alert-danger,
.alert-error,
.label-danger,
.modal-danger .modal-body {
  background-color: #dd142f !important;
}
.bg-yellow,
.callout.callout-warning,
.alert-warning,
.label-warning,
.modal-warning .modal-body {
  background-color: #f39c12 !important;
}
.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #3c8dbc !important;
}
.bg-blue {
  background-color: #0073b7 !important;
}
.bg-light-blue,
.label-primary,
.modal-primary .modal-body {
  background-color: #1d7f9b !important;
}
.bg-green,
.callout.callout-success,
.alert-success,
.label-success,
.modal-success .modal-body {
  background-color: #00a65a !important;
}
.bg-navy {
  background-color: #001f3f !important;
}
.bg-teal {
  background-color: #39cccc !important;
}
.bg-olive {
  background-color: #3d9970 !important;
}
.bg-lime {
  background-color: #01ff70 !important;
}
.bg-orange {
  background-color: #ff851b !important;
}
.bg-fuchsia {
  background-color: #f012be !important;
}
.bg-purple {
  background-color: #605ca8 !important;
}
.bg-maroon {
  background-color: #d81b60 !important;
}
.bg-gray-active {
  color: #000;
  background-color: #b5bbc8 !important;
}
.bg-black-active {
  background-color: #000000 !important;
}
.bg-red-active,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  background-color: #c11129 !important;
}
.bg-yellow-active,
.modal-warning .modal-header,
.modal-warning .modal-footer {
  background-color: #db8b0b !important;
}
.bg-aqua-active,
.modal-info .modal-header,
.modal-info .modal-footer {
  background-color: #357ca5 !important;
}
.bg-blue-active {
  background-color: #005384 !important;
}
.bg-light-blue-active,
.modal-primary .modal-header,
.modal-primary .modal-footer {
  background-color: #186a81 !important;
}
.bg-green-active,
.modal-success .modal-header,
.modal-success .modal-footer {
  background-color: #008d4c !important;
}
.bg-navy-active {
  background-color: #001a35 !important;
}
.bg-teal-active {
  background-color: #30bbbb !important;
}
.bg-olive-active {
  background-color: #368763 !important;
}
.bg-lime-active {
  background-color: #00e765 !important;
}
.bg-orange-active {
  background-color: #ff7701 !important;
}
.bg-fuchsia-active {
  background-color: #db0ead !important;
}
.bg-purple-active {
  background-color: #555299 !important;
}
.bg-maroon-active {
  background-color: #ca195a !important;
}
[class^="bg-"].disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.text-red {
  color: #dd142f !important;
}
.text-yellow {
  color: #f39c12 !important;
}
.text-aqua {
  color: #3c8dbc !important;
}
.text-blue {
  color: #0073b7 !important;
}
.text-black {
  color: #111 !important;
}
.text-light-blue {
  color: #1d7f9b !important;
}
.text-green {
  color: #00a65a !important;
}
.text-gray {
  color: #d2d6de !important;
}
.text-navy {
  color: #001f3f !important;
}
.text-teal {
  color: #39cccc !important;
}
.text-olive {
  color: #3d9970 !important;
}
.text-lime {
  color: #01ff70 !important;
}
.text-orange {
  color: #ff851b !important;
}
.text-fuchsia {
  color: #f012be !important;
}
.text-purple {
  color: #605ca8 !important;
}
.text-maroon {
  color: #d81b60 !important;
}
.link-muted {
  color: #7a869d;
}
.link-muted:hover,
.link-muted:focus {
  color: #606c84;
}
.link-black {
  color: #666;
}
.link-black:hover,
.link-black:focus {
  color: #999;
}
.hide {
  display: none !important;
}
.no-border {
  border: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-shadow {
  box-shadow: none !important;
}
.list-unstyled,
.chart-legend,
.contacts-list,
.users-list,
.mailbox-attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-group-unbordered > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.flat {
  border-radius: 0 !important;
}
.text-bold,
.text-bold.table td,
.text-bold.table th {
  font-weight: 700;
}
.text-sm {
  font-size: 12px;
}
.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}
.bg-teal-gradient {
  background: #39cccc !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #39cccc), color-stop(1, #7adddd)) !important;
  background: -ms-linear-gradient(bottom, #39cccc, #7adddd) !important;
  background: -moz-linear-gradient(center bottom, #39cccc 0%, #7adddd 100%) !important;
  background: -o-linear-gradient(#7adddd, #39cccc) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7adddd', endColorstr='#39cccc', GradientType=0) !important;
  color: #fff;
}
.bg-light-blue-gradient {
  background: #1d7f9b !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #1d7f9b), color-stop(1, #27a9cf)) !important;
  background: -ms-linear-gradient(bottom, #1d7f9b, #27a9cf) !important;
  background: -moz-linear-gradient(center bottom, #1d7f9b 0%, #27a9cf 100%) !important;
  background: -o-linear-gradient(#27a9cf, #1d7f9b) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#27a9cf', endColorstr='#1d7f9b', GradientType=0) !important;
  color: #fff;
}
.bg-blue-gradient {
  background: #0073b7 !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #0073b7), color-stop(1, #0089db)) !important;
  background: -ms-linear-gradient(bottom, #0073b7, #0089db) !important;
  background: -moz-linear-gradient(center bottom, #0073b7 0%, #0089db 100%) !important;
  background: -o-linear-gradient(#0089db, #0073b7) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0089db', endColorstr='#0073b7', GradientType=0) !important;
  color: #fff;
}
.bg-aqua-gradient {
  background: #3c8dbc !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #3c8dbc), color-stop(1, #539dc8)) !important;
  background: -ms-linear-gradient(bottom, #3c8dbc, #539dc8) !important;
  background: -moz-linear-gradient(center bottom, #3c8dbc 0%, #539dc8 100%) !important;
  background: -o-linear-gradient(#539dc8, #3c8dbc) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#539dc8', endColorstr='#3c8dbc', GradientType=0) !important;
  color: #fff;
}
.bg-yellow-gradient {
  background: #f39c12 !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f39c12), color-stop(1, #f7bc60)) !important;
  background: -ms-linear-gradient(bottom, #f39c12, #f7bc60) !important;
  background: -moz-linear-gradient(center bottom, #f39c12 0%, #f7bc60 100%) !important;
  background: -o-linear-gradient(#f7bc60, #f39c12) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7bc60', endColorstr='#f39c12', GradientType=0) !important;
  color: #fff;
}
.bg-purple-gradient {
  background: #605ca8 !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #605ca8), color-stop(1, #9491c4)) !important;
  background: -ms-linear-gradient(bottom, #605ca8, #9491c4) !important;
  background: -moz-linear-gradient(center bottom, #605ca8 0%, #9491c4 100%) !important;
  background: -o-linear-gradient(#9491c4, #605ca8) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9491c4', endColorstr='#605ca8', GradientType=0) !important;
  color: #fff;
}
.bg-green-gradient {
  background: #00a65a !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #00a65a), color-stop(1, #00ca6d)) !important;
  background: -ms-linear-gradient(bottom, #00a65a, #00ca6d) !important;
  background: -moz-linear-gradient(center bottom, #00a65a 0%, #00ca6d 100%) !important;
  background: -o-linear-gradient(#00ca6d, #00a65a) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ca6d', endColorstr='#00a65a', GradientType=0) !important;
  color: #fff;
}
.bg-red-gradient {
  background: #dd142f !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #dd142f), color-stop(1, #ed3750)) !important;
  background: -ms-linear-gradient(bottom, #dd142f, #ed3750) !important;
  background: -moz-linear-gradient(center bottom, #dd142f 0%, #ed3750 100%) !important;
  background: -o-linear-gradient(#ed3750, #dd142f) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ed3750', endColorstr='#dd142f', GradientType=0) !important;
  color: #fff;
}
.bg-black-gradient {
  background: #111 !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #111), color-stop(1, #2b2b2b)) !important;
  background: -ms-linear-gradient(bottom, #111, #2b2b2b) !important;
  background: -moz-linear-gradient(center bottom, #111 0%, #2b2b2b 100%) !important;
  background: -o-linear-gradient(#2b2b2b, #111) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b2b2b', endColorstr='#111', GradientType=0) !important;
  color: #fff;
}
.bg-maroon-gradient {
  background: #d81b60 !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #d81b60), color-stop(1, #e73f7c)) !important;
  background: -ms-linear-gradient(bottom, #d81b60, #e73f7c) !important;
  background: -moz-linear-gradient(center bottom, #d81b60 0%, #e73f7c 100%) !important;
  background: -o-linear-gradient(#e73f7c, #d81b60) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e73f7c', endColorstr='#d81b60', GradientType=0) !important;
  color: #fff;
}
.description-block .description-icon {
  font-size: 16px;
}
.no-pad-top {
  padding-top: 0;
}
.position-static {
  position: static !important;
}
.list-header {
  font-size: 15px;
  padding: 10px 4px;
  font-weight: bold;
  color: #666;
}
.list-seperator {
  height: 1px;
  background: #f4f4f4;
  margin: 15px 0 9px 0;
}
.list-link > a {
  padding: 4px;
  color: #777;
}
.list-link > a:hover {
  color: #222;
}
.font-light {
  font-weight: 300;
}
.user-block:before,
.user-block:after {
  content: " ";
  display: table;
}
.user-block:after {
  clear: both;
}
.user-block img {
  width: 40px;
  height: 40px;
  float: left;
}
.user-block .username,
.user-block .description,
.user-block .comment {
  display: block;
  margin-left: 50px;
}
.user-block .username {
  font-size: 16px;
  font-weight: 600;
}
.user-block .description {
  color: #999;
  font-size: 13px;
}
.user-block.user-block-sm .username,
.user-block.user-block-sm .description,
.user-block.user-block-sm .comment {
  margin-left: 40px;
}
.user-block.user-block-sm .username {
  font-size: 14px;
}
.img-sm,
.img-md,
.img-lg,
.box-comments .box-comment img,
.user-block.user-block-sm img {
  float: left;
}
.img-sm,
.box-comments .box-comment img,
.user-block.user-block-sm img {
  width: 30px !important;
  height: 30px !important;
}
.img-sm + .img-push {
  margin-left: 40px;
}
.img-md {
  width: 60px;
  height: 60px;
}
.img-md + .img-push {
  margin-left: 70px;
}
.img-lg {
  width: 100px;
  height: 100px;
}
.img-lg + .img-push {
  margin-left: 110px;
}
.img-bordered {
  border: 3px solid #d2d6de;
  padding: 3px;
}
.img-bordered-sm {
  border: 2px solid #d2d6de;
  padding: 2px;
}
.attachment-block {
  border: 1px solid #f4f4f4;
  padding: 5px;
  margin-bottom: 10px;
  background: #f7f7f7;
}
.attachment-block .attachment-img {
  max-width: 100px;
  max-height: 100px;
  height: auto;
  float: left;
}
.attachment-block .attachment-pushed {
  margin-left: 110px;
}
.attachment-block .attachment-heading {
  margin: 0;
}
.attachment-block .attachment-text {
  color: #555;
}
.connectedSortable {
  min-height: 100px;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sort-highlight {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
}
.full-opacity-hover {
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.full-opacity-hover:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.chart {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.chart svg,
.chart canvas {
  width: 100% !important;
}
hr {
  border-top: 1px solid #555555;
}
/*
 * Misc: print
 * -----------
 */
@media print {
  .no-print,
  .main-sidebar,
  .left-side,
  .main-header,
  .content-header {
    display: none !important;
  }
  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0 !important;
    min-height: 0 !important;
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    -o-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
  }
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 0 !important;
  }
  .invoice {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
  }
  .invoice-col {
    float: left;
    width: 33.3333333%;
  }
  .table-responsive {
    overflow: auto;
  }
  .table-responsive > .table tr th,
  .table-responsive > .table tr td {
    white-space: normal !important;
  }
}
