// Inline and block code styles
telephone {
	font-family: 'Courier New', Courier, monospace;
	color: #496ec1;
	font-weight: 900;
}

@table-header-color: #3c8dbc !important;
@table-font-color: #ffffff !important;

table.dataTable {
	background-color: #fff;
	thead {
		background-color: @table-header-color;
		color: @table-font-color;
		border-bottom: 1px solid #000000 !important;
		.sorting:after {
			top: 1px;
		}
		.sorting_asc:after {
			top: 1px;
		}
		.sorting_desc:after {
			top: 1px;
		}
		td,
		th {
			padding: 0 18px 2px !important;
			border-right: 1px solid #a1a1a1 !important;
		}
	}
	tfoot {
		background-color: @table-header-color;
		color: @table-font-color;
		border-top: 1px solid #000000 !important;
		td,
		th {
			padding: 0 18px 2px !important;
			border-right: 1px solid #a1a1a1 !important;
		}
	}
	tbody {
		td,
		th {
			padding: 6px 10px !important;
			border-top: 0.1px solid rgb(235, 234, 234) !important;
			border-right: 0.1px solid rgb(235, 234, 234) !important;

			code {
				font-weight: 800;
			}
		}
	}
	.hover {
		tbody {
			tr:hover {
				background-color: red;
			}
		}
	}
}

.certificate-table-content {
	table.dataTable {
		thead {
			background-color: #3c9c86 !important; //#09a782!important
		}
		tfoot {
			background-color: #3c9c86 !important;
		}
	}
}
.agent-table-content {
	table.dataTable {
		thead {
			background-color: #bd3740 !important; //#bd3740!important
		}
		tfoot {
			background-color: #bd3740 !important;
		}
	}
}
