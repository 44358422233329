/*
 * Component: Sidebar Mini
 */

//Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  //Sidebar mini should work only on devices larger than @screen-sm
  @media (min-width: @screen-sm) {
    //When the sidebar is collapsed...
    &.sidebar-collapse {

      //Apply the new margining to the main content and footer
      .content-wrapper,
      .right-side,
      .main-footer {
        margin-left: 50px !important;
        z-index: 840;
      }

      //Modify the sidebar to shrink instead of disappearing
      .main-sidebar {
        //Don't go away! Just shrink
        .translate(0, 0);
        width: 50px !important;
        z-index: 850;
      }

      .sidebar-menu {
        > li {
          position: relative;
          > a {
            margin-right: 0;
          }
          > a > span {
            border-top-right-radius: 4px;
          }

          &:not(.treeview) {
            > a > span {
              border-bottom-right-radius: 4px;
            }
          }

          > .treeview-menu {
            // Add some padding to the treeview menu
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom-right-radius: 4px;
          }
        }
      }

      //Make the sidebar links, menus, labels, badges
      //and angle icons disappear
      .main-sidebar .user-panel > .info,
      .sidebar-form,
      .sidebar-menu > li > a > span,
      .sidebar-menu > li > .treeview-menu,
      .sidebar-menu > li > a > .pull-right,
      .sidebar-menu > li > a > span > .pull-right,
      .sidebar-menu li.header {
        display: none !important;
        -webkit-transform: translateZ(0);
      }

      .main-header {
        //Let's make the logo also shrink and the mini logo to appear
        .logo {
          width: 50px;
          > .logo-mini {
            display: block;
            margin-left: -15px;
            margin-right: -15px;
            font-size: 18px;
          }
          > .logo-lg {
            display: none;
          }
        }

        //Since the logo got smaller, we need to fix the navbar's position
        .navbar {
          margin-left: 50px;
        }
      }
    }
  }
}

@media (min-width: @screen-sm) {
  // Show menu items on hover
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse {
    .sidebar-menu > li:hover {
      > a {
        //overflow: visible;
      }
      > a > span:not(.pull-right), //:not(.pull-right-container),
      > .treeview-menu {
        display: block !important;
        position: absolute;
        width: @sidebar-width - 50;
        left: 50px;
      }

      //position the header & treeview menus
      > a > span {
        top: 0;
        margin-left: -3px;
        padding: 12px 5px 12px 20px;
        background-color: inherit;
      }
      > a > .pull-right-container {
        //display: block!important;
        position: relative !important;
        float: right;
        width: auto !important;
        left: 200px - 20px !important;
        top: -22px !important;
        z-index: 900;
        > .label:not(:first-of-type) {
          display: none;
        }
      }
      > .treeview-menu {
        top: 44px;
        margin-left: 0;
      }
    }
  }
}

.sidebar-expanded-on-hover {
  .main-footer,
  .content-wrapper {
    margin-left: 50px;
  }
  .main-sidebar {
    box-shadow: @sidebar-expanded-shadow;
  }
}

//A fix for text overflow while transitioning from sidebar mini to full sidebar
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden;
}

.sidebar-menu:hover {
  overflow: visible;
}

.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}

.sidebar-menu li > a {
  position: relative;
  > .pull-right-container {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
  }
}
