@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

html {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}
body {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}
a.address {
	text-wrap: normal;
	&:hover {
		cursor: pointer;
	}
}
p.address {
	white-space: pre-wrap;
	font-size: 14px;
	color: #337ab7;
}
a {
	> i {
		padding-right: 5px;
	}
}
input.has-error {
	border-color: #dd4b39;
	box-shadow: none;
}
.dropdown-menu {
	a {
		&:hover {
			cursor: pointer;
		}
	}
}
.validate-form {
	.form-group {
		height: 56px;
	}
}
.tooltip-inner {
	max-width: 1000px;
	text-align: left;
}
.bootstrap-table {
	.table {
		&:not(.table-condensed) {
			padding: 0px 8px;
			> tbody {
				> tr {
					> th {
						padding: 0px 8px;
					}
					> td {
						padding: 0px 8px;
					}
				}
			}
			> tfoot {
				> tr {
					> th {
						padding: 0px 8px;
					}
					> td {
						padding: 0px 8px;
					}
				}
			}
			> thead {
				> tr {
					> td {
						padding: 0px 8px;
					}
				}
			}
		}
		thead {
			> tr {
				> th {
					padding: 0;
					margin: 0;
					background-color: #3c8dbc;
					color: #ffffff;
				}
			}
		}
	}
	.fixed-table-container {
		thead {
			th {
				.th-inner {
					padding: 0px 8px;
				}
			}
		}
		tbody {
			td {
				.th-inner {
					padding: 0px 8px;
				}
			}
		}
	}
	.center {
		text-align: center;
	}
	tbody {
		tr.selected {
			background-color: #b0bed9;
		}
		td {
			font-size: 14px;
		}
	}
}
.bootstrap-dialog {
	.modal-header.bootstrap-dialog-draggable {
		cursor: move;
	}
	.bootstrap-dialog-title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
	}
}
.ztree {
	li {
		a {
			i {
				max-width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.icon-car {
				line-height: 21px;
				font-family: Verdana, Arial, Helvetica, AppleGothic, FontAwesome,
					sans-serif;
			}
			.icon-deviceGroup {
				line-height: 21px;
				font-family: Verdana, Arial, Helvetica, AppleGothic,
					Glyphicons Halflings, sans-serif;
			}
		}
		.toolbar {
			display: inline;
			float: right;
			padding-right: 5px;
			.status {
				display: inline-block;
				text-align: center;
				white-space: nowrap;
				vertical-align: middle;
				border: 0px;
				> i {
					padding: 0px 5px;
				}
			}
			.icon-option-vertical {
				line-height: 21px;
				color: #0087ff;
			}
			.btn {
				display: inline-block;
				text-align: center;
				white-space: nowrap;
				vertical-align: middle;
				border: 0px;
			}
			.dropdown-menu {
				left: auto;
				right: 0px;
				padding: 0px;
				margin: 0px;
				> li {
					> a {
						padding: 5px 10px;
						height: 30px;
						width: 100%;
						text-decoration: none;
					}
				}
			}
		}
	}
	a {
		> i {
			padding-right: 5px;
		}
	}
}
.btn-app {
	> .icon {
		font-size: 20px;
		display: block;
	}
}
.timeline {
	> li {
		> .icon {
			width: 30px;
			height: 30px;
			font-size: 15px;
			line-height: 30px;
			position: absolute;
			border-radius: 50%;
			text-align: center;
			left: 18px;
			top: 0;
		}
	}
	.icon-parking {
		background: red;
		color: #ffffff;
	}
	.icon-trip {
		background: #3c8dbc;
		color: #ffffff;
	}
}
.bv-form {
	.form-control-feedback {
		position: absolute;
		top: 10px;
	}
	.form-control-feedback.icon-loading {
		width: 14px;
		height: 14px;
		right: 25px;
	}
}
.icon-search {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e003';
	}
}
.icon-deviceGroup {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e021';
	}
}
.icon-columns {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e011';
	}
}
.icon-zoomIn {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e015';
	}
}
.icon-zoomOut {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e016';
	}
}
.icon-option-vertical {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e235';
	}
}
.icon-recycle {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e020';
	}
}
.icon-delete {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e020';
	}
}
.icon-edit {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\270f';
	}
}
.icon-valid {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e013';
	}
}
.icon-invalid {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e014';
	}
}
.icon-plus {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\002b';
	}
}
.icon-minus {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\2212';
	}
}
.icon-expand {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e118';
	}
}
.icon-collapse {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e117';
	}
}
.icon-transfer {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e178';
	}
}
.icon-car {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f1b9';
	}
}
.icon-collapse-down {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #337ab7;
	&:before {
		content: '\f103';
	}
}
.icon-collapse-left {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #337ab7;
	&:before {
		content: '\f100';
	}
}
.icon-collapse-right {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #337ab7;
	&:before {
		content: '\f101';
	}
}
.icon-cluster {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f140';
	}
}
.icon-poi-icon {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f1c5';
	}
}
.icon-poi-label {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f035';
	}
}
.icon-fitMap {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f247';
	}
}
.icon-stopDrawing {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f256';
	}
}
.icon-geofence-circle {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f1db';
	}
}
.icon-geofence-rectangle {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f096';
	}
}
.icon-alarm {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0a2';
	}
}
.icon-message {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f003';
	}
}
.icon-dashboard {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0e4';
	}
}
.icon-loading {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
	&:before {
		content: '\f021';
	}
}
.icon-time {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f017';
	}
}
.icon-resendCommand {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f05a';
	}
}
.icon-map {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f278';
	}
}
.icon-play {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f04b';
	}
}
.icon-pause {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f04c';
	}
}
.icon-reset {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f01e';
	}
}
.icon-bind {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0c1';
	}
}
.icon-unbind {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f127';
	}
}
.icon-handRight {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0a4';
	}
}
.icon-bound-select {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f096';
	}
}
.icon-configuration {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f085';
	}
}
.icon-save-map-view {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0ac';
	}
}
.icon-show {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f06e';
	}
}
.icon-hide {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f070';
	}
}
.icon-reportItem {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-circle-check {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f058';
	}
}
.icon-users {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0c0';
	}
}
.icon-map-marker {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f041';
	}
}
.icon-truck {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f0d1';
	}
}
.icon-table-edit {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f044';
	}
}
.icon-circle-check.check {
	color: green;
}
.icon-circle-check.uncheck {
	color: grey;
}
.treeview-menu {
	> li {
		> a {
			> i {
				&:before {
					content: '\f10c';
				}
			}
		}
	}
	> li.active {
		> a {
			> i {
				&:before {
					content: '\f192';
				}
			}
		}
	}
}
.icon-geofence-polyline {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e625';
	}
}
.icon-geofence-polygon {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e658';
	}
}
.icon-geofence {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e61a';
	}
}
.icon-poi {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e631';
	}
}
.icon-engine {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e7ef';
	}
}
.icon-satellite {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e768';
	}
}
.icon-historyReplay {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e61c';
	}
}
.icon-multi-screen {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e71b';
	}
}
.icon-report {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e7d4';
	}
}
.icon-system {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e637';
	}
}
.icon-fullscreen {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e65b';
	}
}
.icon-exit-fullscreen {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e638';
	}
}
.icon-traffic {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\ed6f';
	}
}
.icon-ignition {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\ef59';
	}
}
.icon-parking {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e836';
	}
}
.icon-signal-wire {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\efc4';
	}
}
.icon-case {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\eec1';
	}
}
.icon-trip {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e641';
	}
}
.icon-command {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e6ad';
	}
}
.icon-map-layer {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e665';
	}
}
.icon-report-menu-driving {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e616';
	}
}
.icon-report-menu-common {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e6a5';
	}
}
.icon-report-menu-alarm {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e601';
	}
}
.icon-report-menu-geofence {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e64b';
	}
}
.icon-report-menu-fuel {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e618';
	}
}
.icon-report-menu-rfid {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e701';
	}
}
.icon-report-menu-peripherals {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e617';
	}
}
.icon-report-menu-custom {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e640';
	}
}
.icon-load-address {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e66b';
	}
}
.icon-battery {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\f4ef';
		color: gray;
	}
}
.icon-motor {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e62b';
		color: gray;
	}
}
.icon-lock {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\eda0';
		color: gray;
	}
}
.icon-cover {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: '\e609';
		color: gray;
	}
}
.icon-battery.state-battery-0 {
	&:before {
		content: '\f4f4';
		color: orange;
		font-size: 22px;
	}
}
.icon-battery.state-battery-1 {
	&:before {
		content: '\f4ef';
		color: red;
	}
}
.icon-battery.state-battery-2 {
	&:before {
		content: '\f4f0';
		color: green;
	}
}
.icon-battery.state-battery-3 {
	&:before {
		content: '\f4f3';
		color: green;
	}
}
.icon-battery.state-battery-4 {
	&:before {
		content: '\f4f1';
		color: green;
	}
}
.icon-battery.state-battery-5 {
	&:before {
		content: '\f4f2';
		color: green;
	}
}
.icon-motor.state-fault {
	&:before {
		color: red;
	}
}
.icon-motor.state-normal {
	&:before {
		color: green;
	}
}
.icon-lock.state-lock {
	&:before {
		content: '\eda0';
		color: green;
	}
}
.icon-lock.state-unlock {
	&:before {
		content: '\e68a';
		color: red;
	}
}
.icon-cover.state-open {
	&:before {
		content: '\e607';
		color: red;
	}
}
.icon-cover.state-close {
	&:before {
		content: '\e60c';
		color: green;
	}
}
.icheck-tile {
	width: 100%;
	overflow: auto;
	> label {
		margin-left: 10px;
		width: 23%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		> div {
			position: relative;
			margin-right: 5px;
		}
	}
}
.info {
	margin-left: 10px;
	margin-right: 10px;
	display: inline-block;
	width: 18px;
	height: 18px;
	background-image: url('/static/dist/roadsmart/images/info.png');
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	overflow: hidden;
	-moz-background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
}
.fullscreen-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background-color: #ffffff;
	text-align: center;
	z-index: 10000;
}
.loading {
	.overlay {
		z-index: 50;
		background: rgba(255, 255, 255, 0.7);
		border-radius: 3px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.icon-loading {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -15px;
			margin-top: -15px;
			color: #000;
			font-size: 30px;
		}
	}
}
// .main-header {
// 	padding: 0px;
// 	height: 50px;
// 	.logo {
// 		background-image: url(/dist/roadsmart/images/logo.png);
// 		background-position: center;
// 		background-repeat: no-repeat;
// 		background-attachment: scroll;
// 		overflow: hidden;
// 		-moz-background-size: auto;
// 		-webkit-background-size: auto;
// 		-o-background-size: auto;
// 		background-size: auto;
// 	}
// 	.navbar {
// 		height: 50px;
// 		.main-menu {
// 			.nav {
// 				> li {
// 					> a {
// 						line-height: 51px;
// 						height: 51px;
// 						padding-top: 0px;
// 						padding-bottom: 0px;
// 						font-size: 17px;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.main-menu {
// 		i {
// 			font-size: 20px;
// 			text-align: center;
// 			vertical-align: middle;
// 		}
// 		i.icon-poi {
// 			font-size: 29px;
// 		}
// 		i.icon-command {
// 			font-size: 23px;
// 		}
// 		i.icon-geofence {
// 			font-size: 23px;
// 		}
// 		i.icon-report {
// 			font-size: 23px;
// 		}
// 	}
// 	.navbar-custom-menu {
// 		.messages-menu {
// 			.dropdown-menu {
// 				width: 400px;
// 				max-height: 500px;
// 				> li {
// 					.menu {
// 						max-height: 400px;
// 						.icon-recycle {
// 							font-size: 33px;
// 							color: #3c8dbc;
// 						}
// 					}
// 				}
// 			}
// 		}
// 		.notifications-menu {
// 			.dropdown-menu {
// 				width: 400px;
// 				max-height: 500px;
// 				> li {
// 					.menu {
// 						max-height: 400px;
// 						.icon-recycle {
// 							font-size: 33px;
// 							color: #3c8dbc;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
.main-content {
	height: -moz-calc(100% - 51px);
	height: -webkit-calc(100% - 51px);
	height: calc(100% - 51px);
	width: 100%;
}
.easyui-layout {
	.content {
		padding-left: 0px;
		padding-right: 0px;
	}
}
.xm-panel {
	width: 100%;
	height: 100%;
}
.map {
	width: 100%;
	height: 100%;
}
.mt-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	text-wrap: normal;
	overflow: hidden;
}
.columns {
	margin-left: 5px;
	line-height: 34px;
}
.icheck-radio-group {
	display: inline-flex;
	height: 34px;
	line-height: 34px;
	justify-content: space-between;
	align-items: center;
	> label {
		margin-left: 10px;
		> .iradio_minimal-blue {
			position: relative;
			margin-right: 5px;
		}
	}
}
.icheck-checkbox-group {
	display: inline-flex;
	height: 34px;
	line-height: 34px;
	justify-content: space-between;
	align-items: center;
	> label {
		margin-left: 10px;
		> div {
			position: relative;
			margin-right: 5px;
		}
	}
}
.icheck-vertical-group {
	> label {
		margin-bottom: 10px;
		width: 90%;
		> div {
			position: relative;
			margin-right: 5px;
		}
	}
}
.icheck-checkbox-vertical-group {
	> label {
		margin-bottom: 10px;
		width: 90%;
		> div {
			position: relative;
			margin-right: 5px;
		}
	}
}
.mt-map-info {
	td.title {
		width: 150px;
	}
	td.content {
		word-wrap: break-word;
	}
}
.map-container {
	width: 100%;
	height: 100%;
	.map-controls {
		position: absolute;
		top: 0px;
		right: 0px;
		.btn-group-vertical {
			display: block;
			margin-bottom: 10px;
		}
		.btn-group-horizontal {
			display: inline-flex;
			margin-left: 10px;
			vertical-align: middle;
		}
		button {
			padding: 4px 8px;
			height: 35px;
			> i {
				font-size: 20px;
				line-height: 24px;
			}
			> .icon-geofence-polygon {
				font-size: 24px;
				font-weight: bold;
			}
			> .icon-geofence-polyline {
				font-size: 18px;
				font-weight: bold;
			}
		}
		button.active {
			background-color: #1b99bd;
			color: #ffffff;
		}
	}
}
.mt-infoWindow {
	width: 100%;
	tbody {
		> tr {
			> td {
				padding: 3px;
				border-top: 0px;
			}
		}
	}
	.title {
		font-weight: bold;
		text-align: right;
		width: 130px;
	}
	.content {
		word-break: break-all;
	}
}
.infoWindow-device-content {
	width: 430px;
	border: 2px inset #3c8dbc;
	border-radius: 4px;
	cursor: default;
	table {
		margin-bottom: 0px;
		> tbody {
			> tr {
				> td {
					padding: 3px;
					border-top: 0px;
				}
			}
		}
		td.title {
			font-weight: bold;
			text-align: right;
			width: 130px;
		}
		td.status_title {
			font-weight: bold;
			text-align: right;
			width: 130px;
			vertical-align: middle;
		}
		td.content {
			word-break: break-all;
		}
		td.status {
			vertical-align: middle;
			i {
				padding-right: 3px;
			}
		}
	}
}
.infoWindow-device-title {
	span {
		padding-left: 5px;
	}
}
.infoWindow-device-function {
	padding: 6px 0px 6px 0px;
	i {
		cursor: pointer;
		font-size: 24px;
		padding-left: 6px;
	}
}
.poi-title-parking {
	display: inline-block;
	background-color: red;
	font-size: 15px;
	.index {
		display: inline-block;
		background-color: green;
		color: white;
		padding: 0px 5px 0 5px;
		font-style: italic;
		font-weight: bold;
		text-align: center;
	}
	.content {
		display: inline-block;
		color: white;
		min-height: 0px;
	}
}
.poi-title-idling {
	display: inline-block;
	background-color: red;
	font-size: 15px;
	.index {
		display: inline-block;
		background-color: green;
		color: white;
		padding: 0px 5px 0 5px;
		font-style: italic;
		font-weight: bold;
		text-align: center;
	}
	.content {
		display: inline-block;
		color: white;
		min-height: 0px;
	}
}
.poi-title-alarm {
	display: inline-block;
	background-color: red;
	font-size: 15px;
	.index {
		display: inline-block;
		background-color: green;
		color: white;
		padding: 0px 5px 0 5px;
		font-style: italic;
		font-weight: bold;
		text-align: center;
	}
	.content {
		display: inline-block;
		color: white;
		min-height: 0px;
	}
}
.fleet {
	.panel-body {
		font-size: 14px;
	}
	#map_info {
		width: 100%;
		height: 100%;
	}
	#map_info_close {
		position: absolute;
		top: 0px;
		right: 0px;
	}
	#toolbar {
		display: inline-block;
		padding-left: 10px;
		padding-top: 5px;
		overflow: hidden;
	}
	td.vehicleName {
		&:hover {
			cursor: pointer;
		}
		a {
			text-wrap: normal;
			color: #ffffff;
		}
		.icon-handRight.active {
			display: inline-block;
		}
		.icon-handRight {
			display: none;
		}
	}
	#layout_device_tree {
		.nav-tabs-custom {
			height: -moz-calc(100% - 45px);
			height: -webkit-calc(100% - 45px);
			height: calc(100% - 45px);
			margin-bottom: 0px;
			.tab-content {
				height: 100%;
				padding: 0px;
				.tab-pane {
					height: 100%;
				}
			}
		}
	}
	#dd_alarm {
		.menu {
			> li {
				> a {
					p {
						margin: 0px;
					}
					.menu-item-title {
						font-weight: bold;
						span {
							color: #999999;
							font-size: 10px;
							font-weight: normal;
						}
					}
					.form-group.active {
						display: block;
					}
					.form-group {
						display: none;
					}
					button.active {
						display: inline-block;
						margin-right: 10px;
					}
					button {
						display: none;
					}
				}
			}
		}
	}
	.vehicleDashboard {
		height: 100%;
		overflow-y: auto;
		> .row {
			margin-left: -15px;
			margin-right: -15px;
			> label {
				padding-left: 0px;
			}
		}
		.banner {
			font-weight: bold;
			color: #ffffff;
			background-color: #3c8dbc;
			> label {
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}
	}
}
.historyReplay {
	// .panel-body {
	// 	font-size: 14px;
	// }
	#map_info {
		width: 100%;
		height: 100%;
	}
	#map_info_close {
		position: absolute;
		top: 0px;
		right: 0px;
	}
	.form-horizontal {
		.form-group {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	.form-group {
		margin-bottom: 5px;
	}
	.input-group-addon {
		> label {
			margin-bottom: 0px;
			> div {
				position: relative;
				margin-right: 10px;
			}
		}
	}
	.input-group {
		.spinner {
			width: 0px;
		}
	}
	#toolbar {
		display: inline-block;
		padding-left: 10px;
		padding-top: 5px;
		overflow: hidden;
	}
	#layout_condition {
		.icheck-vertical-group {
			display: inline-block;
			margin-top: 5px;
			position: absolute;
			right: 0px;
		}
	}
	.map-container .marker-checkbox-panel {
		position: absolute;
		width: 0px;
		height: 0px;
		top: 0px;
		left: 0px;
	}
}
// .historyReplay {
// 	.content-wrapper {
// 		height: 100%;
// 		width: 100%;
// 	}
// 	#toolbar {
// 		display: inline-block;
// 		padding-left: 10px;
// 		padding-top: 5px;
// 		overflow: hidden;
// 	}
// 	.form-horizontal {
// 		.form-group {
// 			margin-left: 0px;
// 			margin-right: 0px;
// 		}
// 	}
// 	.form-group {
// 		margin-bottom: 5px;
// 	}
// 	.input-group-addon {
// 		> label {
// 			margin-bottom: 0px;
// 			> div {
// 				position: relative;
// 				margin-right: 10px;
// 			}
// 		}
// 	}
// 	.input-group {
// 		.spinner {
// 			width: 0px;
// 		}
// 	}
// 	.timeline-body {
// 		p {
// 			margin: 0 0 0px;
// 		}
// 		a.address {
// 			font-size: 11px;
// 		}
// 	}
// 	.timeline {
// 		.timeline-header {
// 			.toolbar {
// 				display: inline-block;
// 				float: right;
// 			}
// 			span.label {
// 				&:hover {
// 					cursor: pointer;
// 				}
// 			}
// 		}
// 	}
// 	#layout_condition {
// 		.icheck-vertical-group {
// 			display: inline-block;
// 			margin-top: 5px;
// 			position: absolute;
// 			right: 0px;
// 		}
// 	}
// 	.map-container {
// 		.marker-checkbox-panel {
// 			position: absolute;
// 			width: 0px;
// 			height: 0px;
// 			top: 0px;
// 			left: 0px;
// 			.icheck-vertical-group {
// 				margin-top: 5px;
// 				margin-left: 5px;
// 			}
// 		}
// 	}
// 	#box_timeline {
// 		overflow-y: auto;
// 		height: -moz-calc(100% - 320px);
// 		height: -webkit-calc(100% - 320px);
// 		height: calc(100% - 320px);
// 	}
// }
.command-chat {
	.modal-dialog {
		width: 1000px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 600px;
	}
	.direct-chat {
		.direct-chat-history {
			text-align: center;
			> a {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.direct-chat-messages {
		height: 510px;
		width: auto;
	}
	.direct-chat-text {
		word-break: break-all;
	}
	.direct-chat-builtIn {
		width: 95%;
		margin-bottom: 10px;
		.form-group {
			margin-bottom: 5px;
		}
	}
	.direct-chat-canbus {
		width: 100%;
		margin-bottom: 10px;
		.direct-chat-canbus-checkbox-group {
			width: 100%;
			> label {
				margin-left: 10px;
				width: 30%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				> div {
					position: relative;
					margin-right: 5px;
				}
			}
		}
	}
	.vehicleTree {
		height: 600px;
	}
}
.vehicleSelector {
	width: auto;
	height: 500px;
	overflow: auto;
}
.geofence-management {
	.modal-dialog {
		width: 1200px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 600px;
	}
	.content {
		background-color: #ffffff;
	}
	table {
		table-layout: fixed;
	}
	.radio-group {
		padding-top: 7px;
		> label {
			margin-left: 10px;
			> div {
				position: relative;
				margin-right: 10px;
			}
		}
	}
}
.poi-management {
	.modal-dialog {
		width: 1200px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 600px;
	}
	.content {
		background-color: #ffffff;
	}
	table {
		table-layout: fixed;
	}
	.tab {
		height: 150px;
		border: 1px solid #f4f4f4;
	}
	.nav-tabs {
		li {
			a {
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.tab-content {
		height: 115px;
	}
	.tab-pane {
		height: 100%;
		overflow: auto;
		li {
			list-style-type: none;
			display: block;
			padding: 5px;
			width: 80px;
			height: 50px;
			float: left;
		}
		li.active {
			background-color: Gray;
		}
	}
}
.bound-select-vehicles {
	.modal-dialog {
		width: 1200px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 600px;
	}
	.content {
		background-color: #ffffff;
	}
}
.map-view-configuration {
	.modal-dialog {
		width: 1200px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 600px;
	}
	.content {
		background-color: #ffffff;
	}
	.map {
		width: 1150px;
		height: 520px;
	}
}
.notification-configuration {
	.modal-dialog {
		width: 800px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 600px;
	}
	.content {
		background-color: #ffffff;
	}
	.tab-content {
		height: 500px;
		.tab-pane {
			height: 100%;
			.box {
				height: 100%;
			}
			.box-body {
				height: -moz-calc(100% - 55px);
				height: -webkit-calc(100% - 55px);
				height: calc(100% - 55px);
				> div {
					height: 100%;
				}
			}
		}
	}
	.icheck-tile {
		border: 1px inset #3c8dbc;
		height: 100%;
		> label {
			width: 95%;
		}
	}
}
.load-sensor-configuration-management {
	.modal-dialog {
		width: 800px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 520px;
	}
	.content {
		background-color: #ffffff;
	}
}
.popup-alarm-choose {
	.modal-dialog {
		width: 1000px;
	}
	.bootstrap-dialog-message {
		width: auto;
		height: 520px;
	}
	.content {
		background-color: #ffffff;
	}
	.alarm-group {
		overflow-y: auto;
		width: 100%;
		height: 100%;
		> label {
			margin-left: 10px;
			width: 22%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			> div {
				position: relative;
				margin-right: 5px;
			}
		}
	}
}
.vehicleTree {
	height: 100%;
	.vehicleTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.vehicleTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.driverTree {
	height: 100%;
	.driverTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.driverTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.userTree {
	height: 100%;
	.userTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.userTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.geofenceTree {
	height: 100%;
	.geofenceTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.geofenceTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.truckTree {
	height: 100%;
	.truckTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.truckTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.thingTree {
	height: 100%;
	.thingTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.thingTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.trailerTree {
	height: 100%;
	.trailerTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.trailerTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.clientTree {
	height: 100%;
	.clientTree-toolbar {
		height: 34px;
		line-height: 34px;
		width: 100%;
	}
	.clientTree-content {
		width: 100%;
		height: -moz-calc(100% - 34px);
		height: -webkit-calc(100% - 34px);
		height: calc(100% - 34px);
		overflow: auto;
	}
}
.report {
	.report-tabs {
		> .tab-content {
			> .tab-pane {
				width: 100%;
				height: 100%;
			}
		}
		> .nav-tabs {
			height: 31px;
			overflow: hidden;
		}
	}
	.clearfix {
		margin-bottom: 5px;
	}
	.report-content {
		width: 100%;
		height: 100%;
		overflow: hidden;
		.input-tree.shown {
			position: absolute;
			width: 327px;
			height: 500px;
			background-color: #ffffff;
			z-index: 999;
			border-bottom: 1px solid #66afe9;
			border-left: 1px solid #66afe9;
			border-right: 1px solid #66afe9;
		}
		.input-tree.hidden {
			position: absolute;
			display: none;
		}
		.condition-container {
			height: 34px;
			.input-group {
				width: 400px;
			}
		}
		.chart-container.collapse {
			display: block;
			> button {
				margin-bottom: 5px;
			}
			.chart {
				display: none;
			}
		}
		.chart-container.expand {
			display: block;
			> button {
				display: none;
			}
			.chart {
				height: 250px;
			}
		}
		.table-container.collapse {
			display: block;
			.bootstrap-table {
				display: none;
			}
			.toolbar {
				display: none;
			}
		}
		.table-container.expand {
			display: block;
			> button {
				display: none;
			}
		}
		.table-container {
			.toolbar {
				height: 40px;
				width: 100%;
				display: inline-block;
				overflow: hidden;
				margin-bottom: -10px;
				.search-text-group {
					width: 250px;
					padding-left: 5px;
				}
			}
		}
		.row-container {
			overflow-y: auto;
			.daily-useage-container {
				width: 95%;
				height: 120px;
				border-radius: 3px;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
				border: 1px solid #d2d6de;
				margin: 15px;
			}
			.temperature-container {
				width: 95%;
				height: 250px;
				border-radius: 3px;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
				border: 1px solid #d2d6de;
				margin: 15px;
			}
		}
	}
	.report-content.loading {
		.overlay {
			position: relative;
			top: -100%;
		}
	}
	.report-content.no-chart {
		.chart-container {
			display: none;
		}
	}
	.report-content.no-table {
		.table-container {
			display: none;
		}
	}
}
.daily-useage-container {
	.title {
		position: relative;
		font-size: 16px;
		font-weight: bold;
		margin-left: 10px;
	}
	.chart {
		width: 100%;
		height: 100px;
		margin-top: -30px;
		margin-bottom: -10px;
		position: relative;
	}
	.footer {
		position: relative;
		width: 100%;
	}
}
.temperature-container {
	.chart {
		width: 100%;
		height: 100%;
	}
}
.system {
	.mt-tabs {
		> .tab-content {
			> .tab-pane {
				width: 100%;
				height: 100%;
			}
		}
		> .nav-tabs {
			height: 31px;
			overflow: hidden;
		}
	}
	.clearfix {
		margin-bottom: 5px;
	}
	.system-content {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.system-content.loading {
		.overlay {
			position: relative;
			top: -100%;
		}
	}
}
.chart-columns {
	> label {
		margin-left: 10px;
		width: 23%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		> div {
			margin-right: 5px;
			position: relative;
		}
	}
}
