	// Tree view menu
.treeview-menu {
	display: none;
	list-style: none;
	padding: 0;
	margin: 0;
	padding-left: 5px;
	.treeview-menu {
	  padding-left: 20px;
	}
	> li {
	  margin: 0;
	  > a {
	    padding: 5px 5px 5px 15px;
	    display: block;
	    font-size: 14px;
	    > .fa,
	    > .glyphicon,
	    > .ion {
	      width: 20px;
	    }
	    > .pull-right-container > .fa-angle-left,
	    > .pull-right-container > .fa-angle-down,
	    > .fa-angle-left,
	    > .fa-angle-down {
	      width: auto;
	    }
	  }
	}
}

.treeview {
	> ul.treeview-menu {
		overflow: hidden;
		height:auto;
		padding-top:0px !important;
		padding-bottom: 0px !important;
	}
}
.treeview.menu-open {
	> ul.treeview-menu {
		 overflow: visible;
  		height:auto;
	}
}