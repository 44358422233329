.fullscreen-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background-color: #ffffff;
	text-align: center;
	z-index: 10000;
}

.loading {
	.overlay {
		z-index: 50;
		background: rgba(255, 255, 255, 0.7);
		border-radius: 3px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.icon-loading {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -15px;
			margin-top: -15px;
			color: #000;
			font-size: 30px;
		}
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
.icon-loading {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-right: 5px;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
	&:before {
		content: '\f021';
	}
}

.certificate {
	.loading {
		position: relative;
		top: -100%;
	}
}
