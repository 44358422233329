/*
 * Component: Table
 * ----------------
 */

.table {
	//Cells
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				border-top: 1px solid @box-border-color;
			}
		}
	}
	//thead cells
	> thead > tr > th {
		border-bottom: 2px solid @box-border-color;
	}
	//progress bars in tables
	tr td .progress {
		margin-top: 5px;
	}
}

//Bordered Table
.table-bordered {
	border: 1px solid @box-border-color;
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				border: 1px solid @box-border-color;
			}
		}
	}
	> thead > tr {
		> th,
		> td {
			border-bottom-width: 2px;
		}
	}
}

.table.no-border {
	&,
	td,
	th {
		border: 0;
	}
}

/* .text-center in tables */
table.text-center {
	&,
	td,
	th {
		text-align: center;
	}
}

.table.align {
	th {
		text-align: left;
	}
	td {
		text-align: right;
	}
}
