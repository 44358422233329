.popup-menu-list {
	display: none;
	position: absolute;
	list-style: none;
	text-align: left;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 0;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
	.popup-menu-list-base {
		overflow: auto;
		padding: 10px 10px 0px 10px;
	}
}
.popup-menu-list.open {
	display: block;
	&:before {
		top: 9px;
		right: -8px;
		border: 8px solid transparent;
		border-left-color: #d9d9d9;
		border-right: 0;
		bottom: auto;
		left: auto;
		content: '';
		display: inline-block;
		position: absolute;
	}
	&:after {
		top: 10px;
		right: -7px;
		border: 7px solid transparent;
		border-left-color: #fff;
		border-right: 0;
		bottom: auto;
		left: auto;
		content: '';
		display: inline-block;
		position: absolute;
	}
}
.popup-menu-list-base {
	> li {
		> label {
			> .icheckbox_minimal-blue {
				position: relative;
				margin-right: 5px;
			}
		}
	}
}
.popup-menu.open {
	> .btn-default {
		color: #333;
		background-color: #d4d4d4;
		border-color: #8c8c8c;
	}
	.popup-menu-btn {
		-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		outline: 0;
	}
}
